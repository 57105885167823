import * as React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Checkbox from '@mui/material/Checkbox';
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";

//Types
import { ICheckboxControlProps, PickerItem } from "@/@types/controls/controls";
import { PickerItemValue } from "@/@types/models/model";

function CheckboxControl(props: ICheckboxControlProps) {

  const [items, setItems] = useState<Array<PickerItem>>([]);
  const { value, onChange, field, dc, validation, formMode, controlMode } = props;

  const { t } = useTranslation();

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    getItems();
  }, [dc, value]);

  // useEffect(() => {
  //   if (Array.isArray(value)){
  //     value.forEach(v => {
  //       tempValue.push(this.getItem(v));
  //     })
  //   } else if(Number.isInteger(value)){
  //     const tempValueArray = this.getValues();
  //     tempValueArray.forEach(v => {
  //       tempValue.push(this.getItem(v));
  //     })
  //   }
  // }, [items])

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const itemValue = evt.target.value;
    const checked = evt.target.checked;
    const item = getItem(itemValue);

    const items: Array<PickerItem | undefined> = getValues().map((v) => getItem(v));

    const tempValue: PickerItem[] = items.filter(x => x !== undefined) as PickerItem[];

    if (checked && item !== undefined)  {
      tempValue.push(item);
    } else {
      tempValue.forEach((v, i) => {
        if (v.value === itemValue || v.value === parseInt(itemValue, 10)) {
          tempValue.splice(i, 1);
        }
      });
    }

    if (onChange) {
      onChange(tempValue, field.source);
    }
  };

  const handleToggleAll = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const checked = evt.target.checked;

    if (checked) {
      if (onChange) {
        onChange(items, field.source);
      }
    } else {
      if (onChange) {
        onChange([], field.source);
      }
    }
  };

  const getItems = () => {
    if (field && field.hasOwnProperty("subModel") && dc) {
      dc.GetData().then((data) => {
        if (Array.isArray(data)) {
          setItems(data as Array<PickerItem>);
        } else {
          setItems([])
        }
        // if (dc.filter) {
        //   const items = filter(data);
        //   setItems(items);
        // } else {
        //   setItems(data ? data : []);
        // }
      });
    } else if (field && field.items !== undefined && field.items.values !== undefined) {
      const items: PickerItem[] = [];
      field.items.labels.forEach((label, i) => {
        const v = field.items?.values[i];
        if (v !== undefined && v !== null) {
          items.push({ label: t(label), value: v });
        }
      });
      setItems(items);
    } else {
      setItems([]);
    }
  };

  // const filter = (items) => {
  //   if (dc.filter) {
  //     return items.filter((x) => dc.filter.find((f) => f === x.value));
  //   } else {
  //     return items;
  //   }
  // };

  const getItem = (value: PickerItemValue) => {
    switch (typeof value) {
      case "string":
        let item = items.find((x) => x.value === value)
        if (item === undefined) {
          item = items.find((x) => x.value === parseInt(value, 10));
        }
        return item;
      case "number":
      case "boolean":
        return items.find((x) => x.value === value);
      default:
        return undefined;
    }
  };

  const getValues = () => {
    let valueArray: PickerItemValue[] = [];
    let valueCopy = value;

    if (valueCopy === null) {
      return valueArray;
    } else if (Array.isArray(valueCopy)) {
      if (valueCopy.length >= 0) {
        valueCopy.forEach((el) => {
          if (typeof el === "object") {
            valueArray.push((el as PickerItem).value);
          } else {
            valueArray.push(el as PickerItemValue);
          }
        });
      } else {
        return valueArray;
      }
    } else if (typeof valueCopy === "number") {
      if (Number.isInteger(valueCopy) && valueCopy >= 0) {
        let pow = 1;
        while (valueCopy > 0) {
          if (valueCopy % 2) {
            valueArray.push(pow);
          }
          pow <<= 1;
          valueCopy = Math.floor(valueCopy / 2);
        }
      }
    }

    return valueArray;
  };

  const hasValue = value !== undefined && value !== null;
  const isRequired = field && field.validation && field.validation.required ? true : false;
  const hasError = validation && validation.valid === false;
  const isReadOnly = controlMode === "view" || (field && field.readonly);
  const title = field.ttoken ? t(field.ttoken) : t(field.title);

  const valueArray = getValues();

  const showToggleAll = field && field.showToggleAll
  const allChecked = valueArray.length === items.length;
  const someChecked = valueArray.length > 0 && !allChecked;

  return (
    <FormControl error={hasError}>
      <FormLabel required={isRequired}>{title}</FormLabel>
      <FormGroup row={field.row}
      //value={hasValue ? value.value : ""}
      aria-label={field.title}>
        {showToggleAll ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={allChecked}
                indeterminate={someChecked}
                color="secondary"
                onChange={handleToggleAll}
              />
            }
            value={-1}
            label={"Toggle All"}
            disabled={isReadOnly}
          />
        ) : null}
        { showToggleAll ? <Divider /> : null}
        {items.map((item, i) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={valueArray.indexOf(item.value) >= 0}
                color="secondary"
                onChange={handleChange}
                key={i}
              />
            }
            value={item.value}
            label={t(item.label) as string}
            key={i}
            disabled={isReadOnly || item.disabled}
          />
        ))}
      </FormGroup>
      <FormHelperText id={field.source + "-helper"}>
        {hasError ? t(validation.msg) : field.tooltip ? field.tooltip : " "}
      </FormHelperText>
    </FormControl>
  );
}

export default CheckboxControl;
