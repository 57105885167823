import * as React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

//MUI
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

//Custom Components
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import ThemeSwitch from "@/ui/Header/ThemeSwitch";
import LanguageSelector from "@/ui/Header/LanguageSelector";
import AccessibilityMenu from "@/ui/Header/AccessibilityMenu";

const PublicHeader = () => {
  const { t } = useTranslation();

  const toolbarStyle = {
    "& > button": {
      mx: 1
    },
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    // borderBottom: "2px",
    borderStyle: "solid",
    borderColor: "secondary.main"
  };

  return (
    <div id="header" style={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar variant="dense" sx={toolbarStyle}>
          <ToolbarFillContent>
            <Typography
              variant="h6"
              component="h2"
              sx={{ color: "#fff", fontSize: "1.15rem", flexGrow: 1, textAlign: "center", justifyContent: "center" }}
            >
              <NavLink to="/" style={{
                color: "#fff",
                textDecoration: "none"
              }}>
                {t("app_title")}
              </NavLink>
            </Typography>
          </ToolbarFillContent>
          <LanguageSelector />
          <AccessibilityMenu />
          <ThemeSwitch />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default PublicHeader;
