import React, { useContext} from "react";

//OL
import OlLayerGroup from "ol/layer/Group";

//Custom Components
import TileLayer from "@/components/Map/Layers/TileLayer";
import MapContext from "@/components/Map/MapContext";
import { findLayer, flattenLayers } from "@/lib/olHelpers";

//Types
import { IGeoPortalGSLayerCombinedProps } from "@/@types/views/GIS/map";
import { BoolSettings } from "@/@types/common";

const gs_url = process.env.REACT_APP_GEOSERVERPATH;

function GeoPortalGSLayerCombined(props: IGeoPortalGSLayerCombinedProps) {
  const mapContext = useContext(MapContext);
  const { id, layersCollection, ...otherProps } = props;

  const visibility = mapContext?.getLayerVisibility()
  const hiddenLayers = visibility ? Object.keys(visibility).filter(key => visibility[key] === false) : [];
  // console.log(hiddenLayers);

  const allLayers = flattenLayers(layersCollection.getArray()).filter(x => !(x instanceof OlLayerGroup));
  // console.log(allLayers);

  const visibleLayers = allLayers.filter(x => hiddenLayers.indexOf(x.get("id")) === -1);
  // console.log(visibleLayers);

  const GSlayerNames = visibleLayers.map(x => x.get("layer"));
  // console.log(GSlayerNames);

  return (
    <TileLayer
      id={id}
      title={id}
      {...otherProps}
      visible={true}
      wms={{
        urls: [gs_url],
        params: {
          LAYERS: GSlayerNames.join(","),
          FORMAT: "image/png8",
          TILED: true
        }
      }}
    />
  );
}

export default GeoPortalGSLayerCombined;
