import React from "react";
import { useTranslation } from "react-i18next";

import AppPage from "@/ui/AppPage/AppPage";
import MainMap from "./MainMap";

function MapPage() {
  const { t } = useTranslation();

  return (
    <AppPage>
      <MainMap />
    </AppPage>
  );
}

export default MapPage;
