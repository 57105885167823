import api from "./api/api";

//Types
import { IApi, IApiResponseSuccessGet, Record } from "@/@types/lib/api/api";
import { IDataControllerSub } from "@/@types/lib/dataControllerSub";
import { IFieldID, IFieldPickerModel, IFieldsCollection, PickerItemValue } from "@/@types/models/model";

import { PickerItem } from "@/@types/controls/controls";
import { ISubmodel, ISubmodelField } from "@/@types/models/submodel";

class dataControllerSubModel implements IDataControllerSub {

  api: IApi;

  apiPath: string;
  fields: Array<ISubmodelField>;

  records: Array<PickerItem>

  defaultItem: PickerItemValue | null;

  fieldId: IFieldID;

  constructor(model: ISubmodel, field: IFieldPickerModel) {
    this.apiPath = field.subModel.apiPath;
    this.fields = model.fields;

    this.records = [];

    this.defaultItem = field && field.items && field.items.default ? field.items.default : null;

    const fieldIdName = field.subModel.idattr ? field.subModel.idattr : null;
    if (fieldIdName) {
      this.fieldId = this.fields.find(attr => attr.source === fieldIdName) as IFieldID;
    } else {
      this.fieldId = this.fields.find(attr => attr.idattr === true) as IFieldID;
    }

    this.api = new api();
  }

  GetData(): Promise<Array<PickerItem>> {
    if(this.records.length === 0) {
      return this.api.Call(this.apiPath, "get")
      .then(resp => {
        if (resp.success) {
          const data = (resp as IApiResponseSuccessGet).data;
          if (Array.isArray(data)) {
            const dbRecords = data.map(d => Object.assign({}, d));
            const items = this.mapRecords(dbRecords);
            this.records = items;
            return Promise.resolve(items);
          } else {
            const dbRecords = [data];
            const items = this.mapRecords(dbRecords);
            this.records = items;
            return Promise.resolve(items);
          }
        } else {
          return Promise.reject(resp);
        }
      })
      .catch(err => {
        return Promise.reject(err);
      })
    } else {
      return Promise.resolve(this.records)
    }
  }

  GetRecord(recordId: PickerItemValue) {
    if (this.fieldId && (recordId !== null || recordId !== undefined)) {
      let item = this.records.find(r => r.value === recordId);
      if (item) {
        return Object.assign({}, item); //clone object
      } else {
        return null;
      }
    }
    return null;
  }

  mapRecords(records: Array<Record>) {
    const items: PickerItem[] = [];
    if (Array.isArray(records)) {
      records.forEach(r => {
        if (r.value !== undefined && r.label !== undefined) {
          const item: PickerItem = { value: r.value as PickerItemValue, label: r.label as string};
          if (r.disabled !== undefined && typeof r.disabled === "boolean") {
            item.disabled = r.disabled;
          }
          items.push(item);
        }
      })
    }
    return items;
  }
}
export default dataControllerSubModel;
