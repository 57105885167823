import * as React from "react";
import { Fragment, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

// Material UI Core Components
import Button from "@mui/material/Button";

//Custom Components
import {
  GridContainer,
  GridItem
} from "@/ui/Grid";

//Types
import { IMeasuresPane } from "@/@types/components/MapSidebarPanes";

const LINE = "LineString";
const POLY = "Polygon";
const PT = "Point";

const MeasuresPane: FunctionComponent<IMeasuresPane> = (props) => {
  const { t } = useTranslation();
  const { changeDrawType, handleEraseMeasurements, drawType } = props;

  return (
    <Fragment>
      <GridContainer style={{ marginBottom: "10px" }}>
        <GridItem>
          <Button
            value="length"
            color="secondary"
            onClick={() => changeDrawType(LINE)}
            variant={ drawType === LINE ? "contained" : "outlined"}
            startIcon={<i className="fas fa-ruler-horizontal"/>}
          >
            {t("buttons.line")}
          </Button>
        </GridItem>
        <GridItem>
          <Button
            value="area"
            color="secondary"
            onClick={() => changeDrawType(POLY)}
            variant={ drawType === POLY ? "contained" : "outlined"}
            startIcon={<i className="fas fa-vector-square"/>}
          >
            {t("buttons.area")}
          </Button>
        </GridItem>
        <GridItem>
          <Button
            value="point"
            color="secondary"
            onClick={() => changeDrawType(PT)}
            variant={ drawType === PT ? "contained" : "outlined"}
            startIcon={<i className="fas fa-map-marker-alt"/>}
          >
            {t("buttons.point")}
          </Button>
        </GridItem>
      </GridContainer>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => handleEraseMeasurements()}
        startIcon={<i className="fas fa-trash"/>}
      >
        {t("buttons.erase")}
      </Button>
    </Fragment>
  );
};

export default MeasuresPane;

