import * as React from "react";
import { FunctionComponent } from "react";

//Custom components
import SidebarProvider from "./SidebarContext/SidebarProvider";
import SidebarRoot from "./SidebarRoot";

//Types
import { ISidebarControl } from "@/@types/components/Map/Controls/Sidebar";

const SidebarControl: FunctionComponent<ISidebarControl> = (props) => {
  return (
    <SidebarProvider>
      <SidebarRoot {...props} />
    </SidebarProvider>
  );
};

export default SidebarControl;
