import React from "react";

//MUI
import Radio from "@mui/material/Radio";

function TableRadio({ ...rest }) {
  return (
    <Radio
      {...rest}
      color="secondary"
      size="small"
      sx={{
        ".MuiRadio-root": {
          p: 0
        }
      }}
    />
  );
}

export default TableRadio;
