import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";

//Custom Components
import { GridContainer, GridItem } from "@/ui/Grid";
import FormContent from "@/components/FormContent";
import FormButton from "@/ui/Form/FormButton";
import UserContext from "@/components/UserContext/UserContext";
import useAuthFormController from "@/components/useAuthFormController";

//Types
import { RegistrationFormProps } from "@/@types/views/Auth";

function RegistrationForm(props: RegistrationFormProps) {
  const userContext = useContext(UserContext);

  const { t } = useTranslation();
  let history = useHistory();

  const { dc, form } = props;
  const { record, validation, fields, onFieldChange, doRegister } = useAuthFormController(props);

  const handleFieldChange = (value: any, source: string) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleLogin = () => {
    history.push("/login");
  };

  const handleSubmit = () => {
    if (doRegister) {
      doRegister().then((result) => {
        if (result.success) {
          userContext?.setUserData(result.user);
          history.push("/");
        } else if (result.validationPass === false) {
          console.log(result.validation);
        }
      });
    }
  };

  return (
    <Card>
      <CardContent>
        <GridContainer>
          <GridItem xs={12}>
            <Typography variant="h5" component="h3">
              {t("titles.registration")}
            </Typography>
          </GridItem>
          <FormContent
            fieldNames={dc.getFormFieldsNames(form)}
            record={record}
            validation={validation}
            fields={fields}
            onFieldChange={handleFieldChange}
            onSubmit={handleSubmit}
            mode={"insert"}
            columns={1}
          />
        </GridContainer>
      </CardContent>
      <CardActions>
        <FormButton variant="contained" onClick={handleSubmit}>
          {t("buttons.register")}
        </FormButton>
        <FormButton variant="text" onClick={handleLogin}>
          {t("buttons.login")}
        </FormButton>
      </CardActions>
    </Card>
  );
}

export default RegistrationForm;
