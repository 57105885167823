import React, { FunctionComponent, useState } from 'react'

//Custom
import MapContext from "./MapContext";

//Types
import { BoolSettings } from "@/@types/common";
import { MapContextProviderProps } from "@/@types/context/MapContext";

const MapContextProvider: FunctionComponent<MapContextProviderProps> = (props) => {

  const [ forceRefreshCounter, setForceRefreshCountrs] = useState(0);

  const { map, initOptions} = props;

  const setLayerVisibility = (id: string, isVisible: boolean) => {
    const key: string = "maplayers";

    const item = localStorage.getItem(key)
    const settings = item ? JSON.parse(item) : null;
    if (settings === null || Object.keys(settings).length === 0) {
      localStorage.setItem(key, JSON.stringify({ [id]: isVisible }));
    } else {
      settings[id] = isVisible;
      localStorage.setItem(key, JSON.stringify(settings));
    }

    setForceRefreshCountrs(prev => prev + 1);
  }

  const getLayerVisibility = () => {
    const key: string = "maplayers";

    const item = localStorage.getItem(key)
    const settings = item ? JSON.parse(item) : null;

    return settings ? settings as BoolSettings : {} as BoolSettings;
  }

  const providerValue = {
    map,
    initOptions,
    setLayerVisibility,
    getLayerVisibility,
    forceRefreshCounter

  }

  return (
    <MapContext.Provider value={providerValue}>
      {props.children}
    </MapContext.Provider>
  )

}

export default MapContextProvider;