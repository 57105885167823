import React, { Component, Fragment, Ref } from "react";
import { withTranslation } from "react-i18next";

// d.ts
import { DrawingType, CoordinateSystemType, BoolSettings } from "@/@types/common/index";
import { CardOverlayType, CardPositon, ICardOverlayData, IMapProps, IMapState } from "@/@types/views/GIS/map";

// OpenLayers
import Geometry from "ol/geom/Geometry";
import proj4 from "proj4";
import { register as OlRegister } from "ol/proj/proj4";
import { get as OlGetProjection } from "ol/proj";
import Projection from "ol/proj/Projection";
import OlLayerGroup from "ol/layer/Group";
import OlSourceVector from "ol/source/Vector";
import Map from "@/components/Map/Map";
import MapEvent from "ol/MapEvent";
import { DrawEvent } from "ol/interaction/Draw";
import BaseEvent from "ol/events/Event";
import OlBaseLayer from "ol/layer/Base";
import OlMapBrowserEvent from "ol/MapBrowserEvent";
import OlFeature from "ol/Feature";

// Custom
import {
  Controls,
  CoordZoomStatusControl,
  DefaultControls,
  FullScreenControl,
  MeasureControls,
  RotateControl,
  ScaleLineControl,
  ScaleRatioControl,
  StatusControl,
  ZoomControl,
  ZoomToExtentControl,
  LayerSwitcher,
  LayerTree
} from "@/components/Map/Controls";

import {
  SidebarContent,
  SidebarControl,
  SidebarHeading,
  SidebarPane,
  SidebarTabs,
  SidebarTabList,
  SidebarTabListItem
} from "@/components/Map/Controls/Sidebar";

import { InfoPane, LayersPane, MeasuresPane } from "@/components/MapSidebarPanes";
import { Layers, TileLayer, VectorLayer, GroupLayer } from "@/components/Map/Layers";
import { Overlays, PopupOverlay, MeasureTooltipOverlay } from "@/components/Map/Overlays";
import { Interactions, DefaultInteractions, DrawInteraction } from "@/components/Map/Interactions";
import { drawStyle, measurementsStyle, measuringStyle, selectedStyle } from "@/components/Map/mapStyles";
import UserConsumer from "@/components/UserContext/UserConsumer";
import { flattenLayers, flatLayersCollection } from "@/lib/olHelpers";

import GeoportalBaseLayer from "./GeoportalBaseLayer";
import GeoPortalGSLayer from "./GeoPortalGSLayer";
import GeoPortalGSLayerCombined from "./GeoPortalGSLayerCombined";
import GeoPortalBaseLayerSwitcher from "./GeoPortalBaseLayerSwticher";
import GSInfoCard from "./GSInfoCard";

//Services
import mapService from "@/services/mapService";
import gsService from "@/services/gsService";

class MainMap extends Component<IMapProps, IMapState> {
  private layerSwitcherElementRef: React.RefObject<JSX.Element>;
  private htrs96: Projection;
  private wgs84: Projection;
  private wgs84PM: Projection;
  private defaultViewCenter: [number, number];
  private initialDefaultExtent: [number, number, number, number];
  private drawingSource: OlSourceVector<Geometry>;
  private measurementsSource: OlSourceVector<Geometry>;
  private fullScreenElementRef: React.RefObject<HTMLLIElement>;
  private zoomToExtentElementRef: React.RefObject<HTMLLIElement>;
  constructor(props: IMapProps) {
    super(props);

    this.layerSwitcherElementRef = React.createRef<JSX.Element>();

    //define proj
    proj4.defs(
      "EPSG:3765",
      "+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
    );
    proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
    proj4.defs(
      "EPSG:31276",
      "+proj=tmerc +pm=greenwich +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +ellps=bessel +towgs84=550.499,164.116,475.142,5.80967,2.07902,-11.62386,0.99999445824 +units=m +no_defs"
    );
    proj4.defs(
      "EPSG:3857",
      "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"
    );
    OlRegister(proj4);

    this.htrs96 = OlGetProjection("EPSG:3765");
    this.wgs84 = OlGetProjection("EPSG:4326");
    this.wgs84PM = OlGetProjection("EPSG:3857");

    this.defaultViewCenter = [1731757, 5581737];
    this.initialDefaultExtent = [1688674,5501166,1688774,5501266];

    // this.viewOptions = {
    //   center: this.defaultViewCenter,
    //   zoom: 8,
    //   //extent: this.defaultExtent,
    //   //projection: this.wgs84PM,
    //   minZoom: 8,
    //   maxZoom: 18
    // };

    this.zoomToExtentElementRef = React.createRef();
    this.fullScreenElementRef = React.createRef();

    this.drawingSource = new OlSourceVector({});
    this.measurementsSource = new OlSourceVector({});

    this.handleClick = this.handleClick.bind(this);
    this.handleViewChangeCenter = this.handleViewChangeCenter.bind(this);
    this.handleSidebarPaneChange = this.handleSidebarPaneChange.bind(this);

    this.changeDrawType = this.changeDrawType.bind(this);
    this.handleDrawMeasureStart = this.handleDrawMeasureStart.bind(this);
    this.handleDrawMeasureEnd = this.handleDrawMeasureEnd.bind(this);
    this.handleDrawMeasureChange = this.handleDrawMeasureChange.bind(this);
    this.handleEraseMeasurements = this.handleEraseMeasurements.bind(this);

    this.changeCoordinateSystemDisplay = this.changeCoordinateSystemDisplay.bind(this);
    this.showCardOverlay = this.showCardOverlay.bind(this);
    this.closeOverlays = this.closeOverlays.bind(this);

    this.state = {
      viewOptions: {
        center: this.defaultViewCenter,
        zoom: 8,
        //extent: this.defaultExtent,
        //projection: this.wgs84PM,
        minZoom: 8,
        maxZoom: 22
      },
      baselayersGroup: undefined,
      chosenCoordinateSystem: "WGS84",
      layersCollection: undefined,
      forceRefreshCounter: 0,
      drawType: undefined,
      feature: undefined,
      cardOverlay: undefined,
      defaultExtent: this.initialDefaultExtent
    };
  }

  componentDidMount() {
    this.getLayers();
    this.getDefaultData();
  }

  getLayers() {
    mapService.getLayers().then((coll) => {
      this.setState({
        layersCollection: coll
      });
    });
  }

  getDefaultData() {
    mapService.getDefaultData().then((data) => {
      if (data) {
        const viewData = Array.isArray(data) ? Object.assign({}, data[0]) : Object.assign({}, data);
        this.setState((prevState) => {
          return {
            ...prevState,
            viewOptions: {
              ...prevState.viewOptions,
              zoom: viewData.initial_view_zoom,
              center: viewData.initial_view_center
            },
            zoomToExtent: viewData.default_extent
          };
        });
      }
    });
  }

  getFeatureType(id: number) {
    return "generic";
  }

  setViewCenter(view: { center: number[]; zoom: number }) {
    this.setState((prevState: IMapState) => ({
      viewOptions: Object.assign(prevState.viewOptions, {
        center: view.center,
        zoom: view.zoom
      })
    }));
  }

  changeCoordinateSystemDisplay(type: CoordinateSystemType) {
    this.setState({ chosenCoordinateSystem: type });
  }

  handleClick(evt: OlMapBrowserEvent<any>) {
    const { drawType, layersCollection } = this.state;
    let hit = false;

    if (drawType) {
      return;
    }

    if (!hit) {
      const item = localStorage.getItem("maplayers");
      const visibility = item ? (JSON.parse(item) as BoolSettings) : ({} as BoolSettings);
      const hiddenLayers = visibility ? Object.keys(visibility).filter((key) => visibility[key] === false) : [];
      const allLayers = flattenLayers(layersCollection.getArray()).filter((x) => !(x instanceof OlLayerGroup));
      const visibleLayers = allLayers.filter((x) => hiddenLayers.indexOf(x.get("id")) === -1);
      const GSLayerNames = visibleLayers.map((x) => x.get("layer"));

      gsService.getFeatureInfo(evt.map, evt.pixel, GSLayerNames).then((resp) => {
        if (resp && Object.keys(resp).length != 0) {
          const layerKeys = Object.keys(resp);
          const firstLayer = resp[layerKeys[0]];
          const firstFeature = firstLayer[0];
          const featureType = this.getFeatureType(firstFeature.properties.id as number);
          const data = {
            position: evt.coordinate as CardPositon,
            feature: firstFeature,
            type: featureType as CardOverlayType,
            record: undefined
          } as ICardOverlayData;
          //TODO: depending on ID, define data type, send different id instead of "gsinfo"
          const showCard = () => this.showCardOverlay("gsinfo", data, null);
          this.closeOverlays(showCard);
        } else {
          this.closeOverlays();
        }
      });
    }
  }

  showCardOverlay(type: CardOverlayType, data: ICardOverlayData, ft: OlFeature<any> | null) {
    const { feature } = this.state;

    //TODO: refactor
    feature ? feature.setProperties({ selected: false }) : null;

    if (ft) {
      ft.setProperties({ selected: true });
    }

    if (type === "gsinfo") {
      this.setState({
        cardOverlay: {
          type: type,
          data: data
        },
        feature: ft
      });
    }
  }

  closeOverlays(callback = () => {}) {
    this.setState(
      {
        cardOverlay: undefined
      },
      typeof callback === "function" ? () => callback() : () => {}
    );
  }

  handleViewChangeCenter(evt: MapEvent) {
    const { viewOptions } = this.state;
    if (evt && evt.map) {
      const newView = {
        center: evt.map.getView().getCenter(),
        zoom: evt.map.getView().getZoom()
      };
      this.setState({
        viewOptions: Object.assign(viewOptions, newView)
      });
    }
  }

  handleSidebarPaneChange(/*id*/) {
    const { drawType } = this.state;

    if (drawType) {
      this.setState({
        drawType: undefined
      });
    }
  }

  changeDrawType(type: DrawingType) {
    const { drawType } = this.state;
    if (drawType !== type) {
      this.setState({
        drawType: type,
        measuringFeature: undefined
      });
    } else {
      this.setState({
        drawType: undefined,
        measuringFeature: undefined
      });
    }
  }

  handleDrawMeasureStart(evt: DrawEvent) {
    if (this.drawingSource.getFeatures().length > 0) {
      this.drawingSource.clear();
    }

    this.setState({
      measuringFeature: evt.feature
    });
  }

  handleDrawMeasureEnd(evt: DrawEvent) {
    this.drawingSource.clear();
    this.measurementsSource.addFeature(evt.feature);

    this.setState((prevState: IMapState) => {
      if (prevState.forceRefreshCounter) {
        return {
          forceRefreshCounter: prevState.forceRefreshCounter + 1
        };
      } else {
        return {
          forceRefreshCounter: 1
        };
      }
    });
  }

  handleDrawMeasureChange(evt: BaseEvent) {
    console.log("handleDrawChange", evt);
  }

  handleEraseMeasurements() {
    this.measurementsSource.clear();
  }

  render() {
    const { t } = this.props;
    const {
      baselayersGroup,
      layersCollection,
      chosenCoordinateSystem,
      drawType,
      measuringFeature,
      viewOptions,
      cardOverlay,
      defaultExtent
    } = this.state;

    const gsOverlay = cardOverlay && cardOverlay.type === "gsinfo" ? cardOverlay : undefined;

    return (
      <UserConsumer>
        {(userContext) => (
          <Fragment>
            {userContext && layersCollection ? (
              <Map
                height="800px"
                view={viewOptions}
                onClick={this.handleClick}
                onMoveend={this.handleViewChangeCenter}
                className="sidebar-map"
                id="main-map"
              >
                <Controls>
                  <CoordZoomStatusControl chosenCoordinateSystem={chosenCoordinateSystem} view={viewOptions} />
                  <StatusControl view={viewOptions} changeCoordinateSystem={this.changeCoordinateSystemDisplay} />
                  <ZoomControl
                    zoomInTipLabel={t("map:controls.zoom_in")}
                    zoomOutTipLabel={t("map:controls.zoom_out")}
                  />
                  {/*<ScaleControl className="ol-control ol-scale-ratio ol-sidebar-sticky" ppi={96} />*/}
                  <ScaleLineControl />
                  <ScaleRatioControl viewOptions={viewOptions} ppi={96} />
                  <RotateControl autoHide={false} />
                  {this.fullScreenElementRef && this.fullScreenElementRef.current ?
                    <FullScreenControl
                      tipLabel={t("map:controls.full_screen")}
                      target={this.fullScreenElementRef.current}
                      className="ol-sidebar-control"
                    />
                    : null }

                  {this.zoomToExtentElementRef && this.zoomToExtentElementRef.current ?
                    <ZoomToExtentControl
                      extent={defaultExtent}
                      tipLabel={t("map:controls.zoom_to_extent")}
                      target={this.zoomToExtentElementRef.current}
                      className="ol-sidebar-control"
                    />
                    : null }
                  <GeoPortalBaseLayerSwitcher />
                  <SidebarControl initialOpenId="layers">
                    <SidebarTabs>
                      <SidebarTabList>
                        <SidebarTabListItem
                          id="info"
                          title={t("map:sidebar.info")}
                          icon={<i className="fas fa-info-circle"></i>}
                        />
                        <SidebarTabListItem
                          id="layers"
                          title={t("map:sidebar.layers")}
                          icon={<i className="fas fa-layer-group"></i>}
                        />
                        <SidebarTabListItem
                          id="measures"
                          title={t("map:sidebar.measures")}
                          icon={<i className="fas fa-pencil-ruler"></i>}
                          onChange={this.handleSidebarPaneChange}
                        />
                        <li ref={this.zoomToExtentElementRef}></li>
                        <li ref={this.fullScreenElementRef}></li>
                      </SidebarTabList>
                    </SidebarTabs>
                    <SidebarContent>
                      <SidebarPane id="info">
                        <SidebarHeading title={t("map:sidebar.info")} />
                        <InfoPane />
                      </SidebarPane>
                      <SidebarPane id="layers">
                        <SidebarHeading title={t("map:sidebar.layers")} />
                        <LayerTree ready={layersCollection ? true : false} layersCollection={layersCollection} />
                        {/* <LayerSwitcher ready={layersCollection ? true : false} /> */}
                        {/* <LayersPane layersGroup={true} /> */}
                      </SidebarPane>
                      <SidebarPane id="measures">
                        <SidebarHeading title={t("map:sidebar.measures")} />
                        <MeasuresPane
                          // toggleDraw={this.handleToggleDrawMeasure} TODO: implement method handleToggleDrawMeasure in this component
                          changeDrawType={this.changeDrawType}
                          handleEraseMeasurements={this.handleEraseMeasurements}
                          drawType={drawType}
                        />
                      </SidebarPane>
                    </SidebarContent>
                  </SidebarControl>
                </Controls>
                {/* <Layers id="osm" osm={true} /> */}
                {/* <GeoportalBaseLayer id="osm" zIndex={1} /> */}
                {layersCollection ? (
                  <Layers>
                    <GeoPortalGSLayerCombined id="all-layers" layersCollection={layersCollection} />
                    {/* {layersCollection.getArray().map((layer: OlBaseLayer, i: number) => {
                        if (layer instanceof OlLayerGroup) {
                          return (
                            <GroupLayer
                              key={layer.get("id") + "-" + i}
                              id={layer.get("id")}
                              title={t(layer.get("title"))}
                            >
                              {layer
                                .getLayers()
                                .getArray()
                                .map((childLayer, ci) => {
                                  return (
                                    <GeoPortalGSLayer
                                      key={ci}
                                      id={childLayer.get("id")}
                                      title={t(childLayer.get("title"))}
                                      layer={childLayer.get("layer")}
                                      query={childLayer.get("query")}
                                      zIndex={childLayer.get("zIndex")}
                                    />
                                  );
                                })}
                            </GroupLayer>
                          );
                        } else {
                          return (
                            <GeoPortalGSLayer
                              key={i}
                              id={layer.get("id")}
                              title={t(layer.get("title"))}
                              layer={layer.get("layer")}
                              query={layer.get("query")}
                              zIndex={layer.get("zIndex")}
                            />
                          );
                        }
                      })} */}
                  </Layers>
                ) : null}
                <Overlays>
                  <PopupOverlay
                    id="feature-overlay"
                    position={gsOverlay ? gsOverlay.data.position : undefined}
                    autoPan={true}
                    onClose={() => {}}
                  >
                    {gsOverlay ? <GSInfoCard featureData={gsOverlay.data} onClose={this.closeOverlays} /> : null}
                  </PopupOverlay>
                  <MeasureTooltipOverlay
                    id="measure-overlay"
                    feature={this.state.measuringFeature}
                    position={undefined}
                  />
                </Overlays>
                <Interactions>
                  <DefaultInteractions />
                  {drawType ? (
                    <DrawInteraction
                      source={this.drawingSource}
                      type={drawType}
                      style={measuringStyle}
                      onChange={this.handleDrawMeasureChange}
                      onDrawstart={this.handleDrawMeasureStart}
                      onDrawend={this.handleDrawMeasureEnd}
                    />
                  ) : null}
                </Interactions>
              </Map>
            ) : null}
          </Fragment>
        )}
      </UserConsumer>
    );
  }
}

export default withTranslation()(MainMap);
