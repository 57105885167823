import * as React from "react";
import { FunctionComponent, useContext } from "react";

//Custom components
import SidebarContext from "./SidebarContext/SidebarContext";

//Types
import { ISidebarTabListItem } from "@/@types/components/Map/Controls/Sidebar";
import { SidebarContextType } from "@/@types/components/Map/Controls/Sidebar/SidebarContext";

const SidebarTabListItem: FunctionComponent<ISidebarTabListItem> = (props) => {
  const { id, icon, disabled, title, onChange } = props;

  const sideBarContext = useContext(SidebarContext) as SidebarContextType;
  // const userContext = useContext(UserContext);

  const handlePaneChange = (id: string): void => {
    // userContext.setUserSetting({map_sidebar_pane: id});
    sideBarContext.setActivePane(id);

    if (onChange) {
      onChange(id);
    }
  };

  const isActive = sideBarContext.activePane === id;

  let classNames = [];
  if (disabled) {
    classNames.push("disabled");
  }
  if (isActive) {
    classNames.push("active");
  }
  const joinedClassNames = classNames.length > 0 ? classNames.join(" ") : "";

  return (
    <li className={joinedClassNames} title={title}>
      <a href={"#" + id} role="tab" onClick={(evt) => handlePaneChange(id)}>
        {icon}
      </a>
    </li>
  );
};

export default SidebarTabListItem;
