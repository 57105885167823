import { FunctionComponent, useContext, useEffect } from "react";

import FullScreen from "ol/control/FullScreen";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { registerOlControl } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IFullScreenControl } from "@/@types/components/Map/Controls/OpenLayers";

const FullScreenControl: FunctionComponent<IFullScreenControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const defaultLabelHtmlElement = document.createElement('i')
  defaultLabelHtmlElement.setAttribute('class','fas fa-expand-alt');

  const defaultLabelActiveHtmlElement = document.createElement('i')
  defaultLabelActiveHtmlElement.setAttribute('class','fas fa-compress-alt');

  const options = {
    className: undefined,
    labelActive: defaultLabelActiveHtmlElement,
    label: defaultLabelHtmlElement,
    keys: undefined,
    target: undefined,
    source: undefined
  };

  const events = {
    change: undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => registerOlControl(context, FullScreen, props, options, events), [
    props.className,
    props.label,
    props.labelActive,
    props.tipLabel,
    props.keys,
    props.target,
    props.source
  ]);

  return null;
};

export default FullScreenControl;
