import HomePage from "@/views/HomePage/HomePage";
import MapPage from "@/views/GIS/MapPage/MapPage";
import ProfilePage from "@/views/ProfilePage/ProfilePage";
import UsersPage from "@/views/Administration/UsersPage/UsersPage";

//Types
import { RouteType } from "@/@types/common";

const privateRoutes: RouteType[] = [
  { path: "/profile", name: "Profile", component: ProfilePage},
  { path: "/map", name: "Map", component: MapPage },
	{ path: "/users", name: "Map", component: UsersPage },
	{ path: "/", name: "Home", component: HomePage }
];

export default privateRoutes
