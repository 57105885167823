import * as React from "react";
import { FunctionComponent } from "react";

//Material UI
import { useTheme } from "@mui/material";

const SidebarTabs: FunctionComponent<any> = (props) => {
  const theme = useTheme();

  return (
    <div
      className={"sidebar-tabs"}
      style={{
        backgroundColor: `rgba(${theme.palette.background.default}, 0.4)`
      }}
    >
      {props.children}
    </div>
  );
};

export default SidebarTabs;
