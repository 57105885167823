import * as React from 'react'
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

//MUI
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";

//Types
import { IHeaderIconButton } from "@/@types/ui/Header";

const HeaderIconButton: FunctionComponent<IHeaderIconButton> = (props) => {
  const {t} = useTranslation();
  const { desc, ...rest } = props;

  const title: string = desc ? t(`tooltips.header.${desc}`) : ""

  const style = {
    marginRight: 0,
    color: 'common.white',
    "&:hover": {
      bgcolor: 'secondary.dark'
    }
  };

  return (
    <Tooltip disableFocusListener placement="bottom" arrow title={title}>
      <IconButton size="small" color="secondary" {...rest} sx={style}>
        {props.children}
      </IconButton>
    </Tooltip>
  );
};

export default HeaderIconButton;

