import React, { useContext, MouseEvent } from "react";

//MUI
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";

//Custom Components
import UserContext from "@/components/UserContext/UserContext";

//Types
import { ITableHeaderButtonProps } from "@/@types/ui/Table";

const TableHeaderButton: React.FC<ITableHeaderButtonProps> = (props) => {
  const userContext = useContext(UserContext);
  const theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down("md"));

  const { onClick, needPermission, startIcon, ...otherProps } = props;

  const handleClick = function (evt: MouseEvent<HTMLButtonElement>) {
    onClick(evt);
  };

  return needPermission && userContext !== null && !userContext.hasAnyPermission(needPermission) ? null : isMobile ? (
    <IconButton color="secondary" {...otherProps} onClick={handleClick}>
      {startIcon}
    </IconButton>
  ) : (
    <Button
      color="secondary"
      size="small"
      startIcon={startIcon}
      {...otherProps}
      onClick={handleClick}
      sx={{
        minHeight: 32,
        color: "primary.dark",
        ml: 1,
        mr: 0
      }}
    >
      {props.children}
    </Button>
  );
};

export default TableHeaderButton;
