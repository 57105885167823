import * as React from "react";
import { Fragment, FunctionComponent, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

//Custom components
import Calendar from "Lib/rc-year-calendar/rc-year-calendar";
import 'js-year-calendar/locales/js-year-calendar.hr';
import CalendarRangeEventObject from "js-year-calendar";

//Types
import { ICalendarControl } from "@/@types/controls/controls";
import { IDate } from "@/@types/common";

const CalendarControl: FunctionComponent<ICalendarControl> = (props) => {

  const {i18n} = useTranslation();

  const {
    records, /*allowOverlap,*/ enableRangeSelection,
    onRangeSelected, /*onDayClick,*/ roundRangeLimits
  } = props;

  const currentYear : number = new Date().getFullYear();

  const lang : string = i18n.language;

  return (
    <Fragment>
      <Calendar
        year={currentYear}
        language={lang}
        style="background"
        roundRangeLimits={roundRangeLimits}
        // allowOverlap={allowOverlap}
        dataSource={records}
        enableRangeSelection={enableRangeSelection}
        onRangeSelected={enableRangeSelection ? (evt: IDate) => onRangeSelected(evt) : () => {
        }}
        // onDayClick={onDayClick ? (evt: IDate) => onDayClick(evt) : () => {
        // }}
      />
    </Fragment>
  );
};

export default CalendarControl;

