import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Box from '@mui/material/Box';

//Custom Components
import AppPage from "@/ui/AppPage/AppPage";
import Dashboard from "@/views/HomePage/Dashboard";

function HomePage() {
  const { t } = useTranslation();

  return (
    <AppPage>
      <Box m={2}>
        <Dashboard />
      </Box>
    </AppPage>
  );
}

export default HomePage;
