import * as React from "react";
import { FunctionComponent, useContext } from "react";

//MUI
import Tab from "@mui/material/Tab";

//Custom Components
import UserContext from "@/components/UserContext/UserContext";

//Types
import { IFormTab } from "@/@types/ui/Form";
import { UserContextType } from "@/@types/context/UserContext";

function a11yProps(index: number): object {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`
  };
}

const FormTab: FunctionComponent<IFormTab> = (props) => {
  const userContext = useContext(UserContext) as UserContextType;
  const { value, label, icon, fields, validation, needPermission, children, ...other } = props;

  let hasError = false;
  if (fields && validation && fields.find((x) => validation.hasOwnProperty(x) && validation[x].valid === false)) {
    hasError = true;
  }

  const tabStyle = {
    minWidth: 100,
    px: 1,
    color: hasError ? "error.dark" : "primary.main"
  };

  return needPermission && !userContext.hasAnyPermission(needPermission) ? (
    <Tab style={{ minWidth: 0, padding: 0 }} {...other}/>
  ) : (
    <Tab value={value} label={label} icon={icon} sx={tabStyle} {...a11yProps(value)} {...other}/>
  );
};

export default FormTab;
