import React from "react";
import { useTranslation } from "react-i18next";

//MUI
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

//Custom components
import IDIcon from "@/icons/IDIcon";
import { IFormHeadingID } from "@/@types/ui/Form";

function FormHeadingID(props: IFormHeadingID) {
  const { t } = useTranslation();

  const { id } = props;

  return (
    <Tooltip title={t("common.id") as string}>
      <div style={{ display: "flex", alignItems: "center", marginLeft: 10 }}>
        <IDIcon sx={{ mr: 1 }} />
        <Typography align="left" variant="subtitle2" className="nodrag">
          {id}
        </Typography>
      </div>
    </Tooltip>
  );
}

export default FormHeadingID;
