import React, { Fragment, useState, useEffect, useContext, useCallback, useMemo } from "react";
import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  useTable,
  useRowSelect,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination
} from "react-table";
import { Column, Row, SortingRule } from "react-table";

//Material UI Core Components
import { useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MaterialUITable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

//Custom Components
import TableCheckbox from "@/ui/Table/TableCheckbox";
import TableRadio from "@/ui/Table/TableRadio";
import TableHeading from "@/ui/Table/TableHeading";
import TableSearchBar from "@/ui/Table/TableSearchBar";
import TableHeaderButton from "./HeaderButtons/TableHeaderButton";
import TableHeaderButtonRefresh from "@/ui/Table/HeaderButtons/TableHeaderButtonRefresh";
import TableHeaderButtonColumnPicker from "@/ui/Table/HeaderButtons/TableHeaderButtonColumnPicker";
import TableHeaderButtonExport from "@/ui/Table/HeaderButtons/TableHeaderButtonExport";
import TableHeaderButtonFilter from "@/ui/Table/HeaderButtons/TableHeaderButtonFilter";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import { localStorageAPI } from "@/lib/localStorageAPI";
import ThemeContext from "@/components/ThemeContext/ThemeContext";
import UserContext from "@/components/UserContext/UserContext";

import {
  ITableProps,
  IFilterValue,
  RowClickFn,
  GlobalFilterFn,
  RowField
} from "@/@types/ui/Table";

interface IFilterState {
  [key: string]: IFilterValue;
}

interface IColumn {
  source: string;
  hidden: boolean;
}

interface ISort {
  id: string;
  desc?: boolean;
}

function Table(props: ITableProps) {
  // Use the state and functions returned from useTable to build your UI
  const themeContext = useContext(ThemeContext);
  const userContext = useContext(UserContext);

  const [rowId, setRowId] = useState(-1);
  const [recordsNumber, setRecordsNumber] = useState(0);
  const [firstEntry, setFirstEntry] = useState(false);
  const [pageCurrent, setPageCurrent] = useState(0);
  const [sorts, setSorts] = useState([]);

  const { t } = useTranslation();
  const theme = useTheme();

  const {
    columns,
    data,
    records,
    hiddenColumnNames,
    // onToggleColumnVisibility,
    onRefresh,
    children,
    allowColumnPicker,
    allowSelection,
    allowFilter,
    allowExport,
    allowAdd,
    allowSearch,
    addLabel,
    handleAdd,
    // topAlignedSearch,
    dc,
    tableName,
    // viewName,
    title,
    // pagination,
    // headerContent,
    identifier,
    onRowClick,
    onDoubleClick
  } = props;

  const [filters, setFiltersToggle] = useState<IFilterState | null>(userContext !== null ?
    userContext.getRecent("toggle_filter", identifier, {}) as IFilterState
  : null);

  // const selectOnlyOne = function(evt, value, onChange, toggleAllRowsSelected) {
  //   toggleAllRowsSelected(false);
  //   onChange(evt, value);
  // };

  const handleRowClick: RowClickFn = function(evt, toggleRowSelected, toggleAllRowsSelected, recordId) {
    switch (allowSelection) {
      case "none":
        return;
      case "one":
        if (recordId !== rowId) {
          setRowId(recordId);
          toggleAllRowsSelected(false);
          toggleRowSelected();
          if (onRowClick) {
            onRowClick(recordId);
          }
        }
        return;
      case "many":
        toggleRowSelected();
        break;
    }
  };

  const handleDoubleClick: RowClickFn = function(evt, toggleRowSelected, toggleAllRowsSelected, recordId) {
    if (recordId !== rowId) {
      toggleAllRowsSelected(false);
      if (onDoubleClick) {
        onDoubleClick(recordId);
      }
    }
  };

  const initialPageSizeKey = "initialPageSize";
  const src = dc.getSource();

  const initialPageSize = localStorageAPI.getValue(initialPageSizeKey, identifier, 25);

  // If a new type has a return value that is a div, please define value={value} in its props in order for the column to be searchable
  // If that isn't enough for the new type, define a new case for the new type created here in globalFilter
  const globalFilter: GlobalFilterFn = (rows, ids, filterValue) => {
    if (rows && ids && filterValue) {
      return rows.filter((row) => {
        return ids.some((id) => {
          let rowValue = "";
          const field = dc.getField(id);
          if (field && field.type) {
            const type = field.type;

            if (type === "button" || !row.values[id]) {
              return false;
            }

            switch (type) {
              case "date":
              case "datetime":
                rowValue = row.values[id];
                return String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase());
              case "currency":
                rowValue = row.values[id];
                break;
              case "active":
                rowValue = row.values[id];
                break;
              case "text":
                rowValue = row.values[id];
                break;
              default:
                // rowValue = row.values[id] instanceof Object ? row.values[id].props ? row.values[id].props.value : row.values[id](0) : row.values[id];
                rowValue = row.values[id];
                break;
            }
          }

          return String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase());
        });
      });
    }
    return [];
  };

  const initialSorts = useMemo(() => {
    return userContext !== null ?
      userContext.getRecent("sorts", identifier, []) as Array<SortingRule<ISort>>
      : [];
  }, [src]);

  const initialFilters: Array<IFilterValue> = useMemo(() => {
    const filtersArray = [];
    const filters_ = (userContext !== null ?
      userContext.getRecent("filters", identifier, {}) : {}) as { [k: string]: string };
    if (filters !== null) {
      return Object.keys(filters_).map(id => ({ id, value: filters_[id] }));
    }
    return [];
  }, [src]);

  const userDefinedColumnVisibility = userContext !== null ?
    userContext.getRecent("columns", identifier, []) as Array<IColumn>
    : [];

  const userHiddenFieldNames = userDefinedColumnVisibility.filter(x => x.hidden === true).map(x => x.source);
  const userVisibleFieldNames = userDefinedColumnVisibility.filter(x => x.hidden === false).map(x => x.source);
  const uniqueHiddenFieldNames = Array.from(new Set(userHiddenFieldNames.concat(hiddenColumnNames)));

  const finalHiddenColumnNames = uniqueHiddenFieldNames.filter(x => userVisibleFieldNames.indexOf(x) === -1);

  const {
    getTableProps,
    toggleAllRowsSelected,
    headerGroups,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    prepareRow,
    setAllFilters,
    setFilter,
    setHiddenColumns,
    state: { selectedRowIds, sortBy, pageIndex, pageSize, hiddenColumns }
  } = useTable(
    {
      columns: columns,
      autoResetFilters: false,
      data: data,
      initialState: {
        pageSize: initialPageSize,
        hiddenColumns: finalHiddenColumnNames,
        sortBy: initialSorts,
        filters: initialFilters
      },
      globalFilter: globalFilter
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (instance) => {
      instance.allColumns.push((columns) => {
        switch (allowSelection) {
          case "none":
            return [...columns];
          case "one":
          case "many":
            return [
              ...columns,
              {
                id: "selection",
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div>
                    {allowSelection === "many" ? (
                      <TableCheckbox {...getToggleAllRowsSelectedProps()} title={"Toggle"}/>
                    ) : null}
                  </div>
                ),
                Filter: () => (null),
                Cell: ({ row, toggleAllRowsSelected }) => {
                  const { onChange, checked, indeterminate, style } = row.getToggleRowSelectedProps();

                  switch (allowSelection) {
                    case "one":
                      return (
                        <div>
                          <TableRadio
                            // onClick={(evt, val) => { selectOnlyOne(evt, val, onChange, toggleAllRowsSelected) }}
                            checked={checked}
                            style={style}
                            title={"Toggle"}
                          />
                        </div>
                      );
                    case "many":
                    default:
                      return (
                        <div>
                          <TableCheckbox {...row.getToggleRowSelectedProps()} title={"Toggle"} />
                        </div>
                      );
                  }
                }
              }
            ];
          default:
            return [...columns];
        }
      });
    }
  );

  const arrSelectedRowsIds = Object.keys(selectedRowIds).filter((x) => selectedRowIds[x] === true);

  // Try me and remove this boy one more time!
  console.log;

  if (!firstEntry && rows.length !== recordsNumber) {
    setRecordsNumber(rows.length);
    setFirstEntry(true);
  }

  if (pageIndex !== pageCurrent) {
    if (rows.length !== recordsNumber) {
      gotoPage(0);
      setPageCurrent(0);
      setRecordsNumber(rows.length);
    } else {
      gotoPage(pageCurrent);
      setRecordsNumber(rows.length);
    }
  }

  if (Math.floor(rows.length / 10) < pageCurrent || Math.floor(rows.length / 10) < pageIndex) {
    return null;
  }

  const handleToggleFilter = function(evt: MouseEvent<HTMLButtonElement>) {
    if (filters) {
      setAllFilters([]);
      if (userContext !== null) {
        userContext.setRecentFilters(identifier, "", "", Boolean(filters));
      }
    }

    if (userContext !== null) {
      userContext.setRecent("toggle_filter", identifier, {});
    }
    setFiltersToggle(filters === null ? {} : null);
  };

  const setSort = (
    evt: MouseEvent<HTMLElement>,
    // onClick: (evt: MouseEvent<HTMLElement>) => void,
    key: string,
    isDesc: boolean | undefined
  ) => {
    if (evt.shiftKey) {
      let _sorts = userContext !== null ? userContext.getRecent("sorts", identifier, []) as Array<ISort> : [];
      const _item = _sorts.find(x => x.id === key);

      if (isDesc === undefined) {
        if (!_item) {
          _sorts.push({ id: key, desc: false });
        }
      } else if (!isDesc) {
        if (_item) {
          _item.desc = true;
        }
      } else if (isDesc) {
        if (_item) {
          const newSorts = _sorts.filter(x => x.id !== key);
          if (userContext !== null) {
            userContext.setRecent("sorts", identifier, newSorts);
          }
        }
        // onClick(evt);
        return;
      }
      if (userContext !== null) {
        userContext.setRecent("sorts", identifier, _sorts);
      }
    } else {
      const singleSorts = isDesc === undefined ?
        [{ id: null, desc: null }]
      : [{ id: key, desc: isDesc }];
      if (userContext !== null) {
        userContext.setRecent("sorts", identifier, singleSorts);
      }
    }
    // onClick(evt);
  };

  // @Piero delete this
  const paginate = function() {
    if (rows.length >= 30 /*&& pagination*/) {
      return page;
    } else {
      return rows;
    }
  };

  const textFilter = (isActive: boolean, lightTheme: boolean) => {
    if (!isActive) {
      if (lightTheme) {
        return "rgba(0,0,0,0.4)";
      } else {
        return "rgba(250,250,250,0.5)";
      }
    } else {
      return "";
    }
  };

  const handleToggleColumnVisibility = useCallback((source, isVisible) => {
    let _columns = userContext !== null ? userContext.getRecent("columns", identifier, []) as Array<IColumn> : [];
    const _item = _columns.find(x => x.source === source);

    let newHidden;
    if (isVisible) {
      newHidden = hiddenColumns ? hiddenColumns.filter(x => x !== source) : [];
      if (_item) {
        _item.hidden = false;
      } else {
        _columns.push({ source: source, hidden: false });
      }
    } else {
      newHidden = hiddenColumns ? hiddenColumns.concat([source]) : [source];
      if (!_item) {
        _columns.push({ source: source, hidden: true });
      } else {
        _item.hidden = true;
      }
    }

    const cleanArray = Array.from(new Set(newHidden));
    if (userContext !== null) {
      userContext.setRecent("columns", identifier, _columns);
    }
    setHiddenColumns(cleanArray);
    // NOTE: Does not work

    // setUserFieldVisibility(prevState => {
    //   return {
    //     ...prevState,
    //     [source]: isVisible
    //   }
    // })
  }, [hiddenColumns]);

  // useEffect(() => {
  //   setHiddenColumns(hiddenColumnNames);
  // }, [hiddenColumnNames])

  // Render the UI for your table
  return (
    <Fragment>
      <Toolbar variant="regular" disableGutters={true}>
        {title ? <TableHeading>{title}</TableHeading> : null}
        {allowSearch ?
          <TableSearchBar
            // count={preGlobalFilteredRows ? preGlobalFilteredRows.length : 0}
            // value={globalFilter}
            // onChange={setGlobalFilter}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          : null
        }
        {onRefresh ? <TableHeaderButtonRefresh onClick={() => onRefresh()} /> : null}
        {/*{*/}
        {/*  specificFilterField ? (*/}
        {/*      <TableSpecificFilters*/}
        {/*          setFilter={setFilter}*/}
        {/*          specificFilterField={specificFilterField}*/}
        {/*      />*/}
        {/*  ) : null*/}
        {/*}*/}
        <ToolbarFillContent />
        {allowColumnPicker ?
          <TableHeaderButtonColumnPicker columns={columns} hiddenColumnNames={hiddenColumns} onChange={handleToggleColumnVisibility} />
          : null}
        {allowFilter ?
          <TableHeaderButtonFilter filters={Boolean(filters)} onClick={handleToggleFilter} />
          : null}
        {allowExport ?
          <TableHeaderButtonExport dc={dc} rows={rows} records={records} tableName={tableName} />
          : null}
        {allowAdd && handleAdd !== undefined ? (
          <TableHeaderButton onClick={() => handleAdd()} startIcon={<AddIcon />} variant="contained">
            {addLabel}
          </TableHeaderButton>
        ) : null}
      </Toolbar>
      <TableContainer sx={{
        '.MuiTableContainer-root': {
          [theme.breakpoints.down("md")]: {
            overflowX: "scroll"
          }
        }
      }}>
        <MaterialUITable {...getTableProps()} size="small">
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, c) => {
                  const { /*onClick,*/ ...headerProps } = column.getHeaderProps(column.getSortByToggleProps());
                  const id = column["id"];

                  return (
                    <TableCell padding={column.id === "selection" ? "checkbox" : "normal"} {...headerProps}>

                      {column.canSort ? (
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? "desc" : "asc"}
                          onClick={(evt) => setSort(evt, /*onClick,*/ id, column.isSortedDesc)}
                        >
                          <Typography variant="caption" style={{ textTransform: "uppercase", fontWeight: 800 }}>{column.render("Header")}</Typography>
                        </TableSortLabel>
                      ) : (
                        column.render("Header")
                      )}
                      {
                        filters && column.canFilter ? (
                        <div className="table-filter" key={c + "-filter"}>
                          {column.render("Filter")}
                        </div>
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {paginate().map((row: Row, i) => {
              prepareRow(row);
              const { original } = row as RowField;
              // this comment fixes wrong selection on single select tables but what with multi select? -Piero
              const isItemSelected = /*selectedRowIds[pageIndex * pageSize + i] === true ||*/ rowId === original['id'];
              // this piece of work ensures that children of table get selection property. Is it useful here? Nobody knows
              if (isItemSelected && arrSelectedRowsIds.length === 0) {
                arrSelectedRowsIds.push(row.id);
                row.toggleRowSelected();
              }

              // if this row has active as a property it will be a function which will return its boolean value before translation if true is passed to it
              const isActive = !original.hasOwnProperty("active") || original['active'];
              return (
                <TableRow
                  hover={true}
                  selected={isItemSelected}
                  role="check"
                  aria-checked={isItemSelected}
                  {...row.getRowProps()}
                  onDoubleClick={(evt) => handleDoubleClick(evt, row.toggleRowSelected, toggleAllRowsSelected, original['id'])}
                  onClick={(evt) => handleRowClick(evt, row.toggleRowSelected, toggleAllRowsSelected, original['id'])}
                >
                  {row.cells.map((cell) => {
                    return <TableCell {...cell.getCellProps({
                      style: {
                        overflowWrap: "break-word",
                        cursor: "pointer",
                        color: textFilter(isActive, themeContext !== null && themeContext.theme === "light" ? true : false)
                      }
                    })}>{cell.render("Cell")}</TableCell>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                onPageChange={(evt, page) => setPageCurrent(page)}
                page={pageIndex}
                rowsPerPage={pageSize}
                count={rows.length}
                onRowsPerPageChange={(evt) => setPageSize(parseInt(evt.target.value, 10))}
              />
            </TableRow>
          </TableFooter>
        </MaterialUITable>
      </TableContainer>
      <Toolbar variant="dense" disableGutters={true}>
        <div style={{ flexGrow: 1 }} />
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, { selection: arrSelectedRowsIds });
        })}
      </Toolbar>
    </Fragment>
  );
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  allowSelection: PropTypes.oneOf(["none", "one", "many"])
};

export default Table;
