import * as React from "react";
import { FunctionComponent } from "react";

//MUI
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

//Types
import { IFormHeading } from "@/@types/ui/Form";

const FormHeading: FunctionComponent<IFormHeading> = (props) => {
  const { children, header, subheader, subheaderContent, dataChanged } = props;

  const typographyStyle = {
    mx: 2
  };

  return header ? (
    <Stack spacing={0} direction="column">
      <Typography align="left" variant="subtitle1" sx={typographyStyle}>
        {header} {dataChanged ? " *" : null}
      </Typography>
      {subheaderContent ? (
        subheaderContent
      ) : subheader ? (
        <Typography align="left" variant="subtitle2" sx={typographyStyle}>
          {subheader}
        </Typography>
      ) : null}
    </Stack>
  ) : (
    <Typography align="left" variant="subtitle1" sx={typographyStyle}>
      {children}
    </Typography>
  );
};

export default FormHeading;
