import * as React from "react";
import { Fragment, FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

//MUI
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

//MUI Icons
import PersonIcon from "@mui/icons-material/Person";

//Custom Components
import HeaderMenuButton from "@/ui/Header/HeaderMenuButton";
import HeaderMenuIconButton from "@/ui/Header/HeaderMenuIconButton";
import HeaderMenuItem from "@/ui/Header/HeaderMenuItem";
import UserContext from "@/components/UserContext/UserContext";
import { authService } from "@/services/authService";

//Types
import { IProfileMenu } from "@/@types/ui/Header";
import { UserContextType } from "@/@types/context/UserContext";

const ProfileMenu: FunctionComponent<IProfileMenu> = (props) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext) as UserContextType;
  const history = useHistory();

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const handleUserDetails = () => {
    history.push("/profile");
  };

  const handleLogout = () => {
    authService.logout();
    history.push("/login");
  };

  const label = userContext && userContext.firstName ? userContext.firstName : "USER";

  return mdDown ? (
    <HeaderMenuIconButton icon={<PersonIcon />}>
      <HeaderMenuItem label={t("menu.profile")} icon="person" onClick={handleUserDetails} />
      <HeaderMenuItem label={t("menu.logout")} icon="exit_to_app" onClick={handleLogout} />
    </HeaderMenuIconButton>
  ) : (
    <HeaderMenuButton text={label} startIcon={<PersonIcon />} showExpandIcon={true} desc="profilemenu">
      <HeaderMenuItem label={t("menu.profile")} icon="person" onClick={handleUserDetails} />
      <HeaderMenuItem label={t("menu.logout")} icon="exit_to_app" onClick={handleLogout} />
    </HeaderMenuButton>
  );
};

export default ProfileMenu;
