import React from "react";

//MUI Icons
import FingerprintIcon from "@mui/icons-material/Fingerprint";

//Types
import { SvgIconProps } from "@mui/material";

function IDIcon(props: SvgIconProps) {
  return <FingerprintIcon {...props} />;
}

export default IDIcon;
