import api from "../lib/api";

//OpenLayers
import OlSourceOSM from "ol/source/OSM";
import OlSourceVector from "ol/source/Vector";
import OlSourceStamen from "ol/source/Stamen";
import OlSourceTileWMS from "ol/source/TileWMS";
import OlSourceImageWMS from "ol/source/ImageWMS";

import OlLayerTile from "ol/layer/Tile";
import OlLayerImage from "ol/layer/Image";
import OlLayerVector from "ol/layer/Vector";
import OlLayerGroup from "ol/layer/Group";

import OlCollection from "ol/Collection";
import OlBaseLayer from "ol/layer/Base";
import OlTileSource from "ol/source/Tile";

import {
  BaseLayerDefinition,
  BaseLayersData,
  BaseLayerType,
  DefaultMapData,
  IMapService,
  LayerDefinition,
  LayerType
} from "@/@types/services/mapService";
import { TFunction } from "i18next";

const gs_url = process.env.REACT_APP_GEOSERVERPATH;

const mapService: IMapService = {
  getBaseLayers,
  getLayers,
  getDefaultData
};

function getDefaultData() {
  const apiInstance = new api();
  return apiInstance.Call("map/defaults", "get").then((resp) => {
    if (resp && resp.data) {
      return resp.data as DefaultMapData;
    }
    return null;
  });
}
function getBaseLayers(t: TFunction): Promise<OlLayerGroup> {
  const apiInstance = new api();
  return apiInstance.Call("map/baselayers", "get").then((resp) => {
    if (resp.success) {
      const baselayerDefinitions = resp.data as BaseLayersData;
      const baselayers = prepareBaseLayers(baselayerDefinitions, t);
      return new OlLayerGroup({
        layers: baselayers
      });
    } else {
      return new OlLayerGroup({
        layers: []
      });
    }
  });
}

function getLayers(): Promise<OlCollection<OlBaseLayer>> {
  const apiInstance = new api();
  return apiInstance.Call("map/layers", "get").then((resp) => {
    if (resp.success) {
      const layerDefinitions = resp.data as Array<LayerDefinition>;
      const layers = prepareLayers(layerDefinitions);
      const coll = new OlCollection(layers);
      return coll;
    } else {
      return new OlCollection();
    }
  });
}

const getBaseLayerSource = (def: BaseLayerDefinition) => {
  switch (def.layer_type) {
    case "WMS":
    // case BaseLayerType.TileWMS:
      const options = {
        url: def.url,
        projection: def.projection,
        params: {
          LAYERS: def.layer,
          FORMAT: "image/png8"
        }
      };
      return new OlSourceTileWMS(options);
    case "OSM":
      return new OlSourceOSM();
    // case BaseLayerType.Stamen:
    //   return new OlSourceStamen({
    //     layer: def.layer
    //   });
    case "GEOSERVER":
      return new OlSourceTileWMS({
        url: gs_url,
        projection: def.projection,
        params: {
          LAYERS: def.layer,
          FORMAT: "image/png8"
        }
      });
    default:
      return new OlSourceOSM();
  }
};

function prepareBaseLayers(defs: Array<BaseLayerDefinition>, t: TFunction): Array<OlBaseLayer> {
  const layers: Array<OlBaseLayer> = [];
  let first = true;

  defs.forEach((def) => {
    const options = {
      source: getBaseLayerSource(def)
    };

    const layer = new OlLayerTile(options);
    layer.set("id", "base-" + def.id.toString());
    layer.set("title", t(def.ttoken) as string);
    layer.set("type", "base");
    layer.set("baseLayer", true);
    layer.setVisible(def.visible ? true : false);
    //@ts-ignore
    if (typeof layer.getPreview === "function")  {
      const previewPoint = def.preview_point ? def.preview_point : [2015935, 5257500]
      const previewRes = def.preview_res ? def.preview_res : 2;
      //@ts-ignore
      layer.set("preview", layer.getPreview(previewPoint, previewRes)); //projection: EPSG:3857, resolution
    }

    // if (first) {
    //   layer.setVisible(true);
    //   first = false;
    // } else {
    //   layer.setVisible(false);
    // }

    layers.push(layer);
  });

  return layers;
}

function getSource(def: LayerDefinition) {
  switch (def.layer_type) {
    case "GROUP":
      return null;
    case "GEOSERVER":
      const options = {
        url: def.url,
        params: {
          LAYERS: def.layer,
          FORMAT: "image/png8"
        }
      };
      return new OlSourceTileWMS(options);
    // case "GEOSERVER":
    //   const imageWMSoptions = {
    //     url: gs_url as string,
    //     params: {
    //       LAYERS: def.gs_layer_name as string,
    //       FORMAT: "image/png"
    //     }
    //   };
    //   return new OlSourceImageWMS(imageWMSoptions);


    default:
      return null;
  }
}

function getLayer(def: LayerDefinition): OlBaseLayer {
  switch (def.layer_type) {
    case "GROUP":
      let options = {
        fold: "open",
        visible: def.visible,
        openInLayerSwitcher: true
      };
      if (def.ui_switcher_options !== "" && def.ui_switcher_options !== undefined) {
        Object.assign(options, JSON.parse(def.ui_switcher_options));
      }
      return new OlLayerGroup(options);
    case "GEOSERVER":
      return new OlLayerTile({
        source: getSource(def) as OlSourceTileWMS,
        visible: def.visible
      });
    // case "GEOSERVER":
    //   return new OlLayerImage({
    //     source: getSource(def) as OlSourceImageWMS,
    //     visible: def.visible
    //   });
  }
}

function flatDeep(arr: Array<OlBaseLayer>, d: number = 1): Array<OlBaseLayer> {
  return d > 0
    ? arr.reduce(
        (acc: Array<OlBaseLayer>, val) =>
          acc.concat(
            val instanceof OlLayerGroup && val.getLayers().getArray().length > 0
              ? ([val] as Array<OlBaseLayer>).concat(flatDeep(val.getLayers().getArray(), d - 1))
              : val
          ),
        []
      )
    : arr.slice();
}

function prepareLayers(defs: Array<LayerDefinition>): Array<OlBaseLayer> {
  const layers: Array<OlBaseLayer> = [];

  defs.forEach((def: LayerDefinition) => {
    const layer = getLayer(def);
    if (layer) {
      layer.set("id", "app-" + def.code.toString());
      layer.set("title", def.ttoken);
      layer.set("type", "app-defined");
      layer.set("layer", def.layer);
      layer.set("query", def.can_query);

      if (def.parent_layer_code === null || def.parent_layer_code === undefined) {
        layers.push(layer);
      } else {
        const parentCode = def.parent_layer_code;
        const flatLayers: Array<OlBaseLayer> = layers ? flatDeep(layers, 3) : [];
        const parentLayer = flatLayers.find((x) => x.get("id") === "app-" + parentCode) as OlLayerGroup;
        if (parentLayer) {
          parentLayer.setLayers(new OlCollection([...parentLayer.getLayers().getArray(), layer]));
        }
      }
    }
  });

  return layers;
}

export default mapService;
