import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI Core
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material";

// Custom Components
import GridContainer from "@/ui/Grid/GridContainer";
import GridItem from "@/ui/Grid/GridItem";
import BarChart from "@/ui/Charts/BarChart";
import LineGraph from "@/ui/Charts/LineGraph";

function Dashboard() {
  const { t } = useTranslation();
  const theme = useTheme();

  const getData = () => {
    return {
      labels: [
        "common.months.01",
        "common.months.02",
        "common.months.03",
        "common.months.04",
        "common.months.05",
        "common.months.06",
        "common.months.07",
        "common.months.08",
        "common.months.09",
        "common.months.10",
        "common.months.11",
        "common.months.12"
      ],
      series: [[970, 9138, 9082, 6194, 9169, 8552, 3307, 6926, 7620, 4441, 702, 3614]]
    };
  };

  const data = getData();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card style={{ height: "100%" }}>
          <CardHeader title={t("dashboard.bar_request")} style={{ textAlign: "center" }} />
          <CardContent>
            <NavLink to={"/users"}>
              <BarChart data={data} color={theme.palette.secondary.main} />
            </NavLink>
          </CardContent>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card style={{ height: "100%" }}>
          <CardHeader title={t("dashboard.bar_request")} style={{ textAlign: "center" }} />
          <CardContent>
            <NavLink to={"/users"}>
              <LineGraph data={data} color={theme.palette.secondary.main} />
            </NavLink>
          </CardContent>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default Dashboard;
