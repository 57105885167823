import * as React from "react";
import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

//MUI
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

//Custom Components
import HeaderMenuButton from "@/ui/Header/HeaderMenuButton";
import ThemeSwitch from "@/ui/Header/ThemeSwitch";
import LanguageSelector from "@/ui/Header/LanguageSelector";
import ProfileMenu from "@/ui/Header/ProfileMenu";
import ModuleMenu from "@/ui/Header/ModuleMenu";
import AccessibilityMenu from "@/ui/Header/AccessibilityMenu";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";

//Types
import { IAppHeader } from "@/@types/ui/Header";

const AppHeader: FunctionComponent<IAppHeader> = (props) => {
  const { t } = useTranslation();
  const { modul, sidebarOpen, toggleSidebar } = props;
  const theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down("md"));

  const drawerWidth = 240;

  const toolbarStyle = {
    "& > button": {
      mx: { sm: 0, md: 1 }
    },
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    // borderBottom: "2px",
    borderStyle: "solid",
    borderColor: "secondary.main"
  };

  const appBarStyle = {
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: sidebarOpen ? drawerWidth : 0,
    width: sidebarOpen ? `calc(100% - ${drawerWidth}px)` : "100%",
    transition: sidebarOpen
      ? theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
      : theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        })
  };

  return (
    <AppBar position="fixed" sx={appBarStyle}>
      <Toolbar variant="dense" disableGutters={true} sx={toolbarStyle}>
        {!sidebarOpen ? <HeaderMenuButton toggleSidebar={toggleSidebar} /> : null}
        <ToolbarFillContent>
          <Typography variant="h6" component="h2" style={{ color: "#fff", fontSize: "1.15rem" }}>
            <NavLink
              to="/"
              style={{
                color: "#fff",
                textDecoration: "none"
              }}
            >
              {isMobile ? ( !sidebarOpen ? t("app_title_short") : null) : t("app_title") + (modul ? " - " + t("modul." + modul) : "")}
            </NavLink>
          </Typography>
        </ToolbarFillContent>
        <LanguageSelector />
        <AccessibilityMenu />
        <ThemeSwitch />
        <ProfileMenu />
        {/* <ModuleMenu /> */}
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
