import React, { useContext, SyntheticEvent, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

//MUI
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Toolbar from "@mui/material/Toolbar";

//MUI Icons
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

//Custom Components
import useFormController from "@/components/useFormController";
import DynamicDialog from "@/ui/Dialog/DynamicDialog";
import DialogHeader from "@/ui/Dialog/DialogHeader";
import DialogToolbarHeading from "@/ui/Dialog/DialogToolbarHeading";
import { FormAvatar, FormHeading, FormHeadingID } from "@/ui/Form";
import DialogToolbarButtonClose from "@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import DialogBody from "@/ui/Dialog/DialogBody";
import DialogActionButtonClose from "@/ui/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonAdd from "@/ui/Dialog/ActionButtons/DialogActionButtonAdd";
import DialogActionButtonSave from "@/ui/Dialog/ActionButtons/DialogActionButtonSave";
import DialogToolbarMenuButton from "@/ui/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItem from "@/ui/Dialog/DialogToolbarMenuItem";
import DialogContext from "@/ui/DialogContext/DialogContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import GridContainer from "@/ui/Grid/GridContainer";
import FormContent from "@/components/FormContent";
import UserIcon from "@/icons/UserIcon"

//Types
import { ClosingDetails, FormControllerProps } from "@/@types/components/formController";
import { IDialogProps } from "@/@types/ui/DialogContext";

const UserDialog: FunctionComponent<IDialogProps> = (props) => {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const { dc, mode, form, onClose } = props;

  const { record, validation, dataChanged, fields, subModels, onFieldChange, doInsert, doUpdate, doClose } =
    useFormController(props as FormControllerProps);

  const handleFieldChange = (value: any, source: string) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleInsert = (evt: SyntheticEvent) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "insert" });
        } else if (result.error) {
          const msg = "Greška prilikom dodavanja novog zapisa! " + result.error;
          snackbarContext.showNotification(msg, "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleUpdate = (evt: SyntheticEvent) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "update" });
        } else if (result.error) {
          const msg = "Greška prilikom ažuriranja podataka! " + result.error;
          snackbarContext.showNotification(msg, "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleClose = (evt: SyntheticEvent) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false, action: "cancel" });
        } else {
          if (result.shouldSave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const handleActivate = (evt: SyntheticEvent) => {};

  const handleDeactivate = (evt: SyntheticEvent) => {};

  const handleAnonymize = (evt: SyntheticEvent) => {};

  const close = (result: ClosingDetails) => {
    if (result.dataChanged || dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }

    dialogContext.hideDialog();
  };

  return (
    <DynamicDialog open={true} maxWidth={"md"} onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <FormAvatar icon={<UserIcon />} ariaLabel="user"/>
          <FormHeading header={t("titles.user")} subheaderContent={<FormHeadingID id={record.id as number} />} dataChanged={dataChanged} />
          <ToolbarFillContent />
          {mode === "update" ? (
            <DialogToolbarMenuButton>
              {record.status === 2 ? (
                <DialogToolbarMenuItem
                  onClick={handleDeactivate}
                  label={t("buttons.deactivate")}
                  icon={<CancelIcon color="action" />}
                />
              ) : (
                <DialogToolbarMenuItem
                  onClick={handleActivate}
                  label={t("buttons.activate")}
                  icon={<CheckCircleIcon color="action" />}
                />
              )}
              {!record.is_anoniman && record.status !== 2 ? (
                <DialogToolbarMenuItem
                  onClick={handleAnonymize}
                  label={t("buttons.anonymize")}
                  icon={<AccountCircleIcon color="action" />}
                />
              ) : null}
            </DialogToolbarMenuButton>
          ) : null}
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent
              fieldNames={dc.getFormFieldsNames(form)}
              record={record}
              validation={validation}
              fields={fields}
              onFieldChange={handleFieldChange}
              mode={mode}
              columns={1}
              subModels={subModels}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        {mode === "insert" ? (
          <DialogActionButtonAdd variant="contained" onClick={handleInsert} />
        ) : mode === "update" ? (
          <DialogActionButtonSave variant="contained" onClick={handleUpdate} />
        ) : null}
      </DialogActions>
    </DynamicDialog>
  );
}

export default UserDialog;
