import React from "react";
import { grey, lightBlue, lightGreen, orange, purple, red, yellow } from "@mui/material/colors";

export const helpers = {
	hash,
  uuidGenerator,
  uuidv4,
  getRandomColor,
  formatDate,
  formatDateSplit,
  getImageSize,
  retrieveIconColor
};

function uuidGenerator() {
  const S4 = function() {
    return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
  };
  return (S4()+S4()+"-"+S4()+"-"+S4()+S4());
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function hash(string) {
	// one of primes [31, 33, 37, 39, and 41] will produce less than 7 collisions if
	// 		we take over 50000 English words
  const H   = 37;
  let total = 0;

  for (var i = 0; i < string.length; i++) {
    total += H * total + string.charCodeAt(i);
  }
  total %= 1002569; // arbitrary prime number -- so we do not get some huge values

  return parseInt(total);
}

function getRandomColor() {
  const res = Math.random();
  return res < 0.2 ? "#ff5722" :
         res < 0.4 ? "#673ab7" :
         res < 0.6 ? "#e91e63" :
         res < 0.8 ? "#4caf50" :
         "#08f";
}

const dateFormats = {
  today: "HH:mm",
  thisYear: "MM/DD HH:mm",
  prevYear: "YYYY/MM/DD HH:mm"
}

function formatDate(ts1, ts2, format = null) {
  if (format !== null) {
    return ts1.format(dateFormats[format]);
  }
  return (
    ts1.isBefore(ts2, 'year') ? 
      ts1.format(dateFormats.prevYear) 
    : ts1.isBefore(ts2, 'day') ?
      ts1.format(dateFormats.thisYear) 
    : ts1.format(dateFormats.today) 
  );
}

const dateFormatsNoHours = {
  thisYear: "MM/DD HH:mm",
  prevYear: "YYYY/MM/DD HH:mm" 
}

function formatDateSplit(ts) {
  const formatted = ts.format("DD/MM/YYYY");
  return formatted.split('/');
}

function getImageSize(content, contentType, fileType) {
  return new Promise((resolve, reject) => {
    switch(contentType) {
      case "b64": {
        const imgEl = document.createElement("img");
        imgEl.onload = () => {
          const { width, height } = imgEl;
          resolve({ width, height });
        }
        imgEl.onerror = (err) => reject(err);
        imgEl.src = `data:${fileType};base64, ${content}`;
        break;
      }

      default:
        resolve({ width: -1, height: -1 })
        break;
    }
  })
}

function retrieveIconColor(icon){
  switch(icon){
    case "drafts":
      return yellow["700"];
    case "send":
      return lightBlue["500"];
    case "hourglass_empty":
      return grey["500"];
    case "thumb_up":
      return lightGreen["500"];
    case "thumb_down":
      return red["500"];
    case "help":
      return orange["500"];
    case "assignment_turned_in":
      return lightGreen["500"];
    case "commute":
      return purple["500"];
    case "home":
      return lightGreen["500"];
    case "archive":
      return orange["500"];
    case "warning":
      return red["500"];
    case "remove_circle":
      return red["500"];
  }
}
