import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import './styles/layout.css';
import './styles/map.css';
import './styles/react-datetime.css';

import "./i18n";

import * as dotenv from 'dotenv';

dotenv.config();

ReactDOM.render(<App/>, document.getElementById("root"));