import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

//Custom components
import { LayerSwitcherImageControl } from "@/components/Map/Controls";
import mapService from "@/services/mapService";
import MapContext from "@/components/Map/MapContext";

//OL
import OlBaseLayer from "ol/layer/Base";
import OlLayerGroup from "ol/layer/Group";
import Collection from "ol/Collection";

//Types
import { MapContextType } from "@/@types/context/MapContext";

function GeoPortalBaseLayerSwitcher() {
  const [baselayersGroup, setBaselayersGroup] = useState<OlLayerGroup | undefined>(undefined);
  const context: MapContextType | null = useContext(MapContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (context?.map) {
      mapService.getBaseLayers(t).then((olGroup: OlLayerGroup) => {
        if (olGroup) {
          if (context && context.map) {
            const mapLayers: Collection<OlBaseLayer> = context?.map?.getLayers();
            mapLayers.insertAt(0, olGroup);
          }
        }

        setBaselayersGroup(olGroup);
      });
    }
  }, [context?.map]);

  const showBaseLayerSwitcher =
    context?.map && baselayersGroup && baselayersGroup.getLayers && baselayersGroup.getLayers().getArray().length > 0
      ? true
      : false;

  return showBaseLayerSwitcher ? (
    <LayerSwitcherImageControl id="base-layer-switcher" layerGroup={baselayersGroup} />
  ) : null;
}

export default GeoPortalBaseLayerSwitcher;
