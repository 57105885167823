import React, { Fragment, useContext, useEffect, useState, FunctionComponent, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import numbro from "numbro";

//MUI
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// Custom Components
import { formats } from "@/lib/formats";
import { IconView } from "@/ui/Form";

//Types
import { IViewControlProps, PickerItem } from "@/@types/controls/controls";
import { IDataControllerSub, ErrorData } from "@/@types/lib/dataControllerSub";
import { IFieldCurrency, PickerItemValue } from "@/@types/models/model";
import { DCRecord } from "@/@types/lib/dataController";

const ViewControl: FunctionComponent<IViewControlProps> = (props) => {
  const { t } = useTranslation();
  const [items, setItems] = useState<Array<PickerItem>>([]);

  const { field, dc, record } = props;

  useEffect(() => {
    const { value } = props;
    const isSelectedValue =
      (value && value.hasOwnProperty("label")) ||
      (Array.isArray(value) && value.length > 0 && value[0] !== undefined && (value[0] as PickerItem).label);
    if (dc && items.length === 0 && !isSelectedValue) {
      (dc as IDataControllerSub)
        .GetData()
        .then((data) => {
          if (Array.isArray(data)) {
            setItems(data);
          }
        })
        .catch((err: ErrorData) => {});
    }
  }, [dc]);

  const handleLinkAction = (recType: string | null, recId: number | null) => {
    switch (recType) {
      default:
        break;
    }
  };

  const getLabel = () => {
    const { field } = props;

    return field.hasOwnProperty("ttoken") && field.ttoken
      ? t(field.ttoken)
      : field.hasOwnProperty("title") && field.title
      ? t(field.title)
      : "";
  };

  const getValue = (): string | ReactElement => {
    const { field, value } = props;

    // TODO
    // multiline support
    if (value === null || value === "") {
      return "N/A";
    } else {
      switch (field.type) {
        case "date":
          return moment
            .utc(value as moment.Moment | string)
            .local()
            .format(formats.date);
        case "datetime":
          return moment
            .utc(value as moment.Moment | string)
            .local()
            .format(formats.datetime);
        case "boolean":
        case "picker":
        case "radio":
          return getObjectValue();
        case "checkbox":
          if (value !== null || items !== null) {
            const isSelectedValue: boolean =
              (value && value.hasOwnProperty("label")) ||
              (Array.isArray(value) && value.length > 0 && value[0] !== undefined && value[0].hasOwnProperty("label"))
                ? true
                : false;
            const _items: Array<PickerItem> = isSelectedValue
              ? [value as PickerItem]
              : filterItems(value as Array<PickerItemValue>, items);
            const _labels: Array<string> = _items.map((x) => (x ? x.label : ""));
            const translatedLabels: Array<string> = _labels.map((x) => t(x));
            const displayValue = translatedLabels.join("; ");
            return displayValue;
          } else {
            return "N/A";
          }
        case "text":
        case "multiline":
          return value && (typeof value === "string" || typeof value === "number") ? String(value) : "N/A";
        case "wholenum":
        case "numeric":
          // if (field.hasOwnProperty("suffix")) {
          //   return value + " " + field.suffix;
          // } else {
          return String(value);
        // }
        case "currency":
          if (value) {
            const currency = field && (field as IFieldCurrency).currency ? (field as IFieldCurrency).currency : "Kn";
            return numbro(value).formatCurrency({
              mantissa: 2,
              currencySymbol: currency,
              currencyPosition: "postfix",
              spaceSeparated: true
            });
          } else {
            return "N/A";
          }
        // case "HEREautocomplete":
        //   if (value) {
        //     return value.label;
        //   }
        // case "HEREautocompleteViaStops":
        //   if (value) {
        //     const _labels = value.map((x) => (x ? x.value.label : ""));
        //     return (
        //       <ol>
        //         {_labels.map((label) => (
        //           <li>{label}</li>
        //         ))}
        //       </ol>
        //     );
        //   }
        case "link":
          if (value) {
            const recIdField: string | null = field.link ? (field.link.recordIdField as string) : null;
            const recType: string | null = field.link ? (field.link.recordType as string) : null;
            if (recIdField === null || recType === null) {
              return String(value);
            } else {
              const recId: number | null = record.hasOwnProperty(recIdField)
                ? ((record as DCRecord)[recIdField] as number)
                : null;
              return (
                <div style={{ display: "inline-block" }}>
                  {value}
                  <IconButton
                    aria-label={"link"}
                    size="small"
                    onClick={(evt) => {
                      handleLinkAction(recType, recId);
                    }}
                  >
                    <Icon color="secondary">link</Icon>
                  </IconButton>
                </div>
              );
            }
          }
        default:
          return "";
      }
    }
  };

  const getObjectValue = () => {
    const { field, value } = props;

    if (value === null || value === undefined) {
      return "N/A";
    }

    if (typeof value === "number" || typeof value === "boolean") {
      if (dc && items.length > 0 && value) {
        const _value: PickerItem | undefined = items.find((item) => item.value === value);
        return _value ? t(_value.label) : "N/A";
      } else if (
        field.hasOwnProperty("items") &&
        typeof field.items === "object" &&
        field.items.hasOwnProperty("labels") &&
        Array.isArray(field.items.labels) &&
        field.items.hasOwnProperty("values") &&
        Array.isArray(field.items.values)
      ) {
        const valuePos: number = field.items.values.indexOf(value);
        if (valuePos === -1) {
          console.warn("Value has no label");
          return String(value);
          // return value.hastranslationvalue ? t(String(value)) : String(value);
        }

        if (valuePos >= field.items.labels.length) {
          console.error("Not enough labels to match the value position");
          // console.error(
          //   "Current value: " +
          //     value +
          //     ". All values: " +
          //     field.items.values +
          //     ". All labels: " +
          //     field.items.labels +
          //     "."
          // );
        }
        //console.log(field.items.labels[valuePos]);
        return t(field.items.labels[valuePos]);
      } else {
        console.error("Unknown form of dc object");
        return "N/A";
      } //View control will show N/A if there is no dc object
    } else if (typeof value === "object") {
      if (value.hasOwnProperty("label") && (value as PickerItem).label) {
        return t((value as PickerItem).label);
      } else if (value.hasOwnProperty("value") && (value as PickerItem).value) {
        return t(String((value as PickerItem).value));
      } else {
        console.error("Value form is not expected!");
        console.error("Expected value form: { value: some_value, label: some_lable }! Instead got: ", value);
        return "ERROR";
      }
    } else if (typeof value === "string") {
      return t(value);
    } else {
      console.error("Undefined type of value!");
      console.error("Expected one of [string, number, object]! Instead got: " + typeof value + "!");
      return "ERROR";
    }
  };

  const filterItems = (_value: Array<PickerItemValue>, _items: Array<PickerItem>): Array<PickerItem> => {
    return _items.filter((x: PickerItem) => _value.find((f: PickerItemValue) => f === x.value));
  };

  const label = getLabel();
  const value = getValue();

  return (
    <Stack direction="row" spacing={1}>
      {field.icon ? <Icon color="secondary">{field.icon}</Icon> : null}
      <Typography variant="caption" align="right" display="inline">
        {label}:<b> {value}</b>
      </Typography>
    </Stack>
  );
};

export default ViewControl;
