import * as React from "react";

import AppPage from "@/ui/AppPage/AppPage";
import UsersTable from "./UsersTable";

import Box from '@mui/material/Box';

function UsersPage() {
  return (
    <AppPage>
      <Box m={2}>
        <UsersTable />
      </Box>
    </AppPage>
  );
}

export default UsersPage;
