import * as React from "react";
import { FunctionComponent } from "react";
import { grey } from "@mui/material/colors";

//MUI
import Avatar from "@mui/material/Avatar";
import Icon from "@mui/material/Icon";

//Types
import { IFormAvatar } from "@/@types/ui/Form";

const FormAvatar: FunctionComponent<IFormAvatar> = (props) => {
  const { icon, iconWord, ariaLabel } = props;

  const iconStyle = {
    width: 35,
    height: 35,
    display: "inline"
  };

  return (
    <Avatar
      aria-label={ariaLabel}
      sx={{
        bgcolor: grey[50]
      }}
    >
      {iconWord ? (
        <Icon sx={{ iconStyle }} color="primary">
          {iconWord}
        </Icon>
      ) : icon ? (
        <icon.type sx={{ iconStyle }} color="primary" />
      ) : null}
    </Avatar>
  );
};

export default FormAvatar;
