import React, { useState, useEffect } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
//  https://react-dropzone-uploader.js.org/docs/props
import { useTranslation } from "react-i18next";
import FileSaver from "file-saver";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faFilePdf, faFileWord, faFile } from "@fortawesome/free-solid-svg-icons";
import api from "Lib/api";
import arrayBufferToBase64 from "Lib/arrayBufferToBase64";

// Materil UI Icons
import CloseIcon from "@mui/icons-material/Close";

// Material UI Core
import { useTheme } from "@mui/material";
import Badge from "@mui/material/Badge";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import Fab from "@mui/material/Fab";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";

const customStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  fontFamily: "Helvetica, sans-serif",
  fontSize: "20px",
  fontWeight: 600,
  cursor: "pointer",
  backgroundColor: "#FFF",
  margin: 0
};

function FileControl(props) {
  const [fileIds, setFileIds] = useState([]);
  const [fileNumber, setFileNumber] = useState(0);
  const [firstEntry, setFirstEntry] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [activeImage, setActiveImage] = useState(null);
  const [fileValidation, setFileValidation] = useState(null);

  const { t } = useTranslation();
  const theme = useTheme();

  const apiInstance = new api();

  const { field, value, onChange, controlMode, accept, validation } = props;

  useEffect(() => {
    const fIds = [];
    let firstEntry = false;
    if (Array.isArray(value)) {
      value.forEach((val) => {
        if (val.uuid) {
          fIds.push(val.uuid);
        }
      });
      firstEntry = true;
    } else if (value && value.uuid) {
      fIds.push(value.uuid);
      firstEntry = true;
    }

    setFileIds(fIds);
    setFirstEntry(firstEntry);
  }, []);

  useEffect(() => {
    if (!firstEntry) {
      if (Array.isArray(value)) {
        setFileIds(value.map((x) => x.uuid));
        setFirstEntry(false);
      } else if (value && typeof value === "object" && value.hasOwnProperty("uuid")) {
        setFileIds([value.uuid]);
        setFirstEntry(true);
      }
    }
  }, [value]);

  const handleOpenFile = (file) => {
    if (field.type === "images") {
      if (file.hasOwnProperty("image")) {
        setActiveImage(file);
      } else if (file.hasOwnProperty("uuid")) {
        apiInstance.Download(field.type, file.uuid).then((resp) => {
          resp.arrayBuffer().then((data) => {
            const imageB64 = arrayBufferToBase64(data);
            const imageObj = Object.assign({}, file);
            Object.assign(imageObj, { image: imageB64 });

            setActiveImage(imageObj);
          });
        });
      }
    } else if (file.type.indexOf("image") >= 0 && file.hasOwnProperty("uuid")) {
      apiInstance.Download(field.type, file.uuid).then((resp) => {
        resp.arrayBuffer().then((data) => {
          const imageB64 = arrayBufferToBase64(data);
          const imageObj = Object.assign({}, file);
          Object.assign(imageObj, { image: imageB64 });

          setActiveImage(imageObj);
        });
      });
    } else {
      if (file.uuid) {
        const path = field.cms ? "cms/dokumenti" : "dokumenti";
        apiInstance.Download(path, file.uuid).then((resp) => {
          resp.blob().then((data) => {
            const fileBlob = new Blob([data], { type: file.type }, file.name);
            FileSaver.saveAs(fileBlob, file.name);
          });
        });
      }
    }
  };

  const handleRemoveFile = (file) => {
    const fileId = file.uuid || file.id;
    const pos = fileIds.indexOf(fileId);
    const fileList = value ? value.filter((val) => (val.uuid || val.id) !== fileId) : [];

    if (pos >= 0) {
      setFileIds(fileIds.filter((x, i) => i !== pos));
    }

    if (activeImage && fileId === (activeImage.id || activeImage.uuid)) {
      setActiveImage(null);
    }

    if (onChange) {
      onChange(fileList, field.source);
    }
  };

  const handleChangeStatus = ({ meta }, status, files) => {
    const pos = fileIds.indexOf(meta.id); // position of the id in the fileIds array
    let fileList = [];

    /*
     ** if status is 'removed' id is spliced from the array
     ** this has to be done because ( not sure about this part ) of the asinchronism of the removal of the file from the dropbox
     ** if you remove the file from the dropbox it will not be removed from the files array from some reason
     **
     ** the idea is that it is faster to search array of 15-ish long strings than the array of 24kB big b64 strings
     */
    if (status === "error_file_size") {
      files.pop();
      setFileValidation(t("validation.file_too_big"));
    } else if (status === "rejected_file_type") {
      files.pop();
      setFileValidation(t("validation.rejected_file_type"));
    } else {
      setFileValidation(null);
    }

    if (status === "done" && !firstEntry) {
      setFirstEntry(true);
    }

    if (pos >= 0 && status == "removed") {
      fileIds.splice(pos, 1);
      setFileIds(prevState => prevState.filter((x,i) => i !== pos));
      setFileNumber(prevState => prevState - 1);
    } else if (status === "done") {
      setFileIds(prevState => prevState.concat([meta.id]));
      setFileNumber(prevState =>  prevState + 1);
    }

    // if more files is uploaded at once
    const diff = fileIds.length - (Array.isArray(value) ? value.length : 0);

    // if status is removed then the id of the image will not be in fileIds and this piece of code ensures it is not in the fileList
    if (Array.isArray(value)) {
      fileList = value.filter((val) => fileIds.indexOf(val.id || val.uuid) >= 0);
    } else if (status === "removed") {
      fileList = [];
    }

    // if status is done the last item in files is the latest image added
    if (status === "done" && files.length === fileNumber + 1) {
      // files.pop() would be better but then you remove it from the dropbox
      const newImages = [];
      for (let i = 1; i <= diff; i++) {
        newImages.push(files[files.length - i]);
      }

      const filePromise = newImages.map(
        (newImage) =>
          new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => {
              const newFileB64 = arrayBufferToBase64(reader.result);
              const fileObj = {
                content: newFileB64,
                name: newImage.meta.name,
                size: newImage.meta.size,
                type: newImage.meta.type,
                id: newImage.meta.id,
                width: newImage.meta.width,
                height: newImage.meta.height
              };
              fileList.push(fileObj);
            };
            reader.onerror = (e) => {
              console.log("ERROR ===>");
              console.error(e);
            };
            reader.onloadend = () => {
              resolve();
            };
            reader.readAsArrayBuffer(newImage.file);
          })
      );

      Promise.all(filePromise).then(() => {
        if (onChange) {
          onChange(fileList, field.source);
        }
      });
    } else if (status === "removed") {
      // if (onChange) {
      //   onChange(fileList, value)
      // }
    }
  };

  const handleHover = (hovered) => {
    setHovered(hovered);
  };

  const deactivateImage = () => {
    setActiveImage(null);
  };

  const handleSubmit = (files, allFiles) => {
    console.log(files.map((f) => f.meta));
    // allFiles.forEach(f => f.remove())
  };

  // accept is a string with allowed input MIME types (comma separated)

  const isReadOnly = controlMode === "view" || (field && field.readonly);
  const isRequired = field.validation && field.validation.required;
  const hasError = validation && validation.valid === false;
  const hasValue = value && Array.isArray(value) ? true : false;

  const hasMaxFiles = field.hasOwnProperty("maxFiles") ? true : false;
  const maxFiles = hasMaxFiles ? field.maxFiles : 3;
  const valueLength = hasValue ? value.length : 0;
  const maxFilesCalculated = fileNumber + maxFiles - valueLength;

  const label = t(field.ttoken);
  const newStyle = Object.assign({ color: theme.palette.primary.dark }, customStyle);

  const valueArrays = Array.isArray(value) && value.length > 5 ? [value.slice(0, 5), value.slice(5)] : [value];
  return (
    <FormControl margin="none" fullWidth required={isRequired} error={hasError}>
      <FormLabel>
        {`${label} (${t("titles.max")} ${maxFiles} ${
          maxFiles === 1
            ? t("titles.files_sg")
            : maxFiles % 10 >= 2 && maxFiles % 10 < 5
            ? t("titles.files_pl2")
            : t("titles.files_pl1")
        })`}
      </FormLabel>
      <div style={{ display: "flex" }}>
        <Dropzone
          onChangeStatus={handleChangeStatus}
          onSubmit={handleSubmit}
          styles={{
            dropzone: { minHeight: 56, overflow: "auto", width: "50%" },
            preview: { display: "none" },
            inputLabel: newStyle,
            inputLabelWithFiles: newStyle
          }}
          inputWithFilesContent={t("components.dropzone.msg")}
          inputContent={t("components.dropzone.msg")}
          SubmitButtonComponent={null}
          accept={accept}
          maxFiles={maxFilesCalculated}
          multiple
          maxSizeBytes={5 * 1024 * 1024} // 1024*1024 is 1MB
          disabled={isReadOnly}
        />
        <Box
          style={{
            width: "50%",
            border: "2px solid #d9d9d9"
          }}
        >
          <p style={{ position: "absolute", top: 0, right: 0, padding: "4px" }}>{`${valueLength}/${maxFiles}`}</p>
          {Array.isArray(valueArrays)
            ? valueArrays.map((arr, j) => {
                return (
                  <div
                    key={j}
                    style={{
                      display: "flex"
                    }}
                  >
                    {Array.isArray(arr)
                      ? arr.map((val, i) => {
                          let type = val && val.type ? val.type : "";
                          const name =
                            val && val.name
                              ? val.name.length > 13
                                ? val.name.substring(0, 14) + "..."
                                : val.name
                              : "";
                          // if (type === undefined || type === null)  {
                          //   type = '';
                          // }
                          const icon =
                            type.indexOf("pdf") >= 0
                              ? faFilePdf
                              : type.indexOf("image") >= 0
                              ? faImage
                              : type.indexOf("docx") >= 0
                              ? faFileWord
                              : faFile;
                          return (
                            <span key={"div" + i} style={{ margin: 5, textAlign: "center" }}>
                              <FontAwesomeIcon
                                icon={icon}
                                color="grey"
                                size="2x"
                                key={"icon" + i}
                                style={{ margin: "auto" }}
                                onClick={() => handleOpenFile(val)}
                              />
                              {!isReadOnly ? (
                                <Badge
                                  key={"badge" + i}
                                  badgeContent={<CloseIcon fontSize="inherit" />}
                                  color="secondary"
                                  onClick={() => handleRemoveFile(val)}
                                  anchorOrigin={{ horizontal: "left", vertical: "top" }}
                                />
                              ) : null}
                              <label key={"label" + i} style={{ display: "block" }}>
                                {name}
                              </label>
                            </span>
                          );
                        })
                      : null}
                  </div>
                );
              })
            : null}
        </Box>
      </div>
      {activeImage && activeImage.hasOwnProperty("image") ? (
        <Card onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)}>
          {hovered ? (
            <Fab
              size="small"
              style={{ position: "absolute", backgroundColor: "#E52E2E", bottom: 0 }}
              onClick={deactivateImage}
            >
              <CloseIcon />
            </Fab>
          ) : null}
          <img
            src={`data:image/jpeg;base64, ${activeImage.image}`}
            style={{ display: "block", maxWidth: "100%", maxHeight: "100%" }}
          />
        </Card>
      ) : null}
      <FormHelperText id={field.source + "-helper"} error={hasError || fileValidation ? true : false}>
        {hasError ? validation.msg : ""}
        {fileValidation ? fileValidation : ""}
      </FormHelperText>
    </FormControl>
  );
}

export default FileControl;
