import React, { Fragment, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import clsx from "clsx";

//Material-UI Core Components
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActions from "@mui/material/AccordionActions";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

//Material-UI Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssessmentIcon from "@mui/icons-material/Assessment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

//Custom Components
import MapCard from "@/ui/MapCard/MapCard";
import MapCardHeader from "@/ui/MapCard/MapCardHeader";
import MapCardAvatar from "@/ui/MapCard/MapCardAvatar";
import MapCardContent from "@/ui/MapCard/MapCardContent";
import MapCardActions from "@/ui/MapCard/MapCardActions";
import MapCardActionButton from "@/ui/MapCard/MapCardActionButton";
import { formats } from "@/lib/formats";
import { Role } from "@/lib/role";
import dataController from "@/lib/dataController";
import MapCardHeaderButtonClose from "@/ui/MapCard/MapCardHeaderButtonClose";
import DialogContext from "@/ui/DialogContext/DialogContext";
import { GridContainer, GridItem } from "@/ui/Grid";
import ModelTable from "@/ui/Table/ModelTable";
// import { dataService } from "@/services/dataService";
import Form from "@/ui/Form";
import FormContent from "@/components/FormContent";
import UserContext from "@/components/UserContext/UserContext";

// import modelPregled from "Models/pregledi";
// import ImageSlider from "@/components/ImageSlider";

// import modelCvjetnjaci from "Models/cvjetnjaci";
// import modelGrmovi from "Models/grmovi";
// import modelKlupe from "Models/klupe";
// import modelKosevi from "Models/kosevi";
// import modelPutevi from "Models/putevi";
// import modelStabla from "Models/stabla";
// import modelSumskeSastojine from "Models/sumske-sastojine";
// import modelTravnjaci from "Models/travnjaci";
// import modelPhotos360 from "Models/photos360";
import modelData from "Models/data";

// import DataDialog from "Views/MapPage/DataDialog";
// import InspectionDialog from "Views/MapPage/InspectionDialog";

import { dataIcons } from "@/lib/dataIcons";

function GSInfoCard(props) {
  const { t } = useTranslation();

  const initialRecod = props.featureData.record ? props.featureData.record : props.featureData.feature.properties;

  const [record, setRecord] = useState(initialRecod);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [loadStatus, setLoadStatus] = useState({ isLoading: true, isLoaded: false });
  const [imageLoadStatus, setImageLoadStatus] = useState({ imageLoading: true });
  const [expanded, setCollapse] = useState(false);
  const [inspections, setInspections] = useState(null);

  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);

  const { featureData, onClose } = props;

  const { type } = featureData;

  const getModel = () => {
    switch (type) {
      // case "stabla":
      //   return modelStabla;
      // case "grmovi":
      //   return modelGrmovi;
      // case "klupe":
      //   return modelKlupe;
      // case "kosevi":
      //   return modelKosevi;
      // case "travnjaci":
      //   return modelTravnjaci;
      // case "cvjetnjaci":
      //   return modelCvjetnjaci;
      // case "sumske-sastojine":
      //   return modelSumskeSastojine;
      // case "putevi":
      //   return modelPutevi;
      // case "photos360":
      //   return modelPhotos360;
      default:
        return modelData;
    }
  };

  const model = getModel();

  const dc = new dataController(model);

  const faIconClass = dataIcons(type);

  const handleRefresh = function () {
    setRefreshCounter(refreshCounter + 1);

    setLoadStatus({ isLoading: true });
  };

  const handleDetailCollapse = function () {
    setCollapse(!expanded);
  };

  const handleEdit = () => {
    // const model = getModel(type);
    // const dc = new dataController(model);
    // dialogContext.showDialog(DataDialog, {
    //   type: type,
    //   dc: dc,
    //   recordId: record.id,
    //   mode: "update",
    //   form: "edit",
    //   iconClass: faIconClass,
    //   onClose: (result) => handleDataDialogClose(result, record.id)
    // });
  };

  const handleDataDialogClose = (dialogResult, recordId) => {
    if (dialogResult.dataChanged) {
      // dataService.getData(recordId).then((result) => {
      //   if (result.success) {
      //     setRecord(result.data);
      //   }
      // });
    }
  };

  const handlePregledDialogClose = (result) => {
    if (result.dataChanged) {
      refreshInspections();
    }
  };

  const handleAddPregled = (evt) => {
    // const dc = new dataController(modelPregled);
    // const defaultValues = {
    //   autor: userContext.fullName,
    //   datum_pregleda: moment()
    // };
    // dialogContext.showDialog(InspectionDialog, {
    //   dc: dc,
    //   mode: "insert",
    //   form: "default",
    //   defaultValues: defaultValues,
    //   dataId: record.id,
    //   onClose: handlePregledDialogClose
    // });
  };

  const handleEditPregled = (evt, pregled) => {
    // const dc = new dataController(modelPregled);
    // dialogContext.showDialog(InspectionDialog, {
    //   dc: dc,
    //   mode: "update",
    //   form: "default",
    //   dataId: record.id,
    //   recordId: pregled.id,
    //   onClose: handlePregledDialogClose
    // });
  };

  const handleDeletePregled = (evt, pregled) => {
    // dataService.removePregled(record.id, pregled.id).then((resp) => {
    //   if (resp.success) {
    //     refreshInspections();
    //   } else {
    //   }
    // });
  };

  const refreshInspections = () => {
    // dataService.getPreglediById(record.id).then((resp) => {
    //   if (resp) {
    //     setInspections(resp);
    //   } else {
    //     setInspections(null);
    //   }
    //   setLoadStatus({
    //     isLoading: false,
    //     isLoaded: true
    //   });
    // });
  };

  useEffect(() => {
    refreshInspections();
  }, [record.id, refreshCounter]);

  useEffect(() => {
    if (featureData) {
      if (featureData.record) {
        setRecord(featureData.record);
      } else if (featureData.feature) {
        setRecord(featureData.feature.properties);
      }
    }
    setRecord;
  }, [featureData]);

  const form = "default";
  const title = record.naziv ? record.naziv : type ? t("layer." + type) : "Info";

  return (
    <Fragment>
      <MapCard
        sx={{
          backgroundColor: "background.default",
          minWidth: "200px"
        }}
      >
        <MapCardHeader
          title={title}
          subheader={t("common.id") + ": " + record.id}
          subheaderTypographyProps={{ color: "initial" }}
          avatar={<MapCardAvatar ariaLabel="info" icon={<i className={faIconClass}></i>} />}
          action={<MapCardHeaderButtonClose onClick={onClose} />}
        />
        <MapCardContent>
          <GridContainer spacing={0}>
            <GridItem xs={12} container>
              {/* <Form dc={dc} mode="view" form={form} record={record}> */}
                <GridContainer spacing={0}>
                <FormContent
                  dc={dc}
                  form={form}
                  fields={dc.getFormFields(form)}
                  fieldNames={dc.getFormFieldsNames(form)}
                  columns={1}
                  mode="simpleView"
                  record={record}
                />
                </GridContainer>
              {/* </Form> */}
            </GridItem>
            {/* <GridItem xs={6}>Image Placeholder</GridItem> */}
          </GridContainer>
        </MapCardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {inspections && expanded ? (
            <MapCardContent>
              <GridContainer spacing={0} style={{ display: "flex", flexDirection: "column" }}>
                {/*<ModelTable*/}
                {/*    dc={dc}*/}
                {/*    records={inspections}*/}
                {/*    allowSelection={"one"}*/}
                {/*    allowFilter={true}*/}
                {/*    allowExport={true}*/}
                {/*    tableName={t("titles.users")}*/}
                {/*/>*/}
                {inspections.map((inspection, index) => {
                  let photos = inspection.photos && inspection.photos.length > 0 ? inspection.photos : [];
                  photos = photos.filter((x) => x.base64 !== null);

                  return (
                    <Accordion key={"accordion-" + index} style={{ width: "100%" }}>
                      <AccordionSummary
                        key={"accordion-" + index}
                        expandIcon={<ExpandMoreIcon />}
                        id={"panel-" + index}
                        aria-controls="filter-time-content"
                      >
                        <Typography variant="caption" key={"typography-" + index}>
                          <b>{t("pregledi.datum_pregleda")}</b>:{" "}
                          {moment(inspection.datum_pregleda).format(formats.date)}
                        </Typography>
                        <Typography variant="caption">
                          <b>{t("pregledi.biljeska")}</b>: {inspection.biljeska}
                        </Typography>
                        <Typography variant="caption">
                          <b>{t("pregledi.autor")}</b>: {inspection.autor}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails key={"accordiondetails-" + index}>
                        <Typography variant="caption">{t("common.no_photos")}</Typography>
                        {/* {photos && photos.length > 0 ? (
                          <ImageSlider images={photos} />
                        ) : (
                          <Typography variant="caption">{t("common.no_photos")}</Typography>
                        )} */}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
                {/*{inspections.map((inspection, index) => (*/}
                {/*    <GridItem key={"grid-item-" + index} xs={12} style={{display: "flex",flexDirection: "column", marginBottom:"5px"}}>*/}
                {/*      <Typography variant="caption" key={"property-" + index}>*/}
                {/*        <b>Comment</b>: {inspection.biljeska}*/}
                {/*      </Typography>*/}
                {/*      <Typography variant="caption" key={"property-" + index}>*/}
                {/*        <b>Author</b>: {inspection.autor}*/}
                {/*      </Typography>*/}
                {/*      <Typography variant="caption" key={"property-" + index}>*/}
                {/*        <b>Date</b>: {inspection.datum_pregleda}*/}
                {/*      </Typography>*/}
                {/*    </GridItem>*/}
                {/*))}*/}
              </GridContainer>
            </MapCardContent>
          ) : null}
        </Collapse>
      </MapCard>
    </Fragment>
  );
}

export default GSInfoCard;
