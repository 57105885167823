import * as React from "react";
import { FunctionComponent, useState } from "react";

//Custom components
import SidebarContext from "./SidebarContext";

const SidebarProvider: FunctionComponent<any> = (props) => {

  const [activePane, setPane] = useState<string | null>("layers");
  const [collapsed, setCollapse] = useState<boolean>(false);

  const setActivePane = (id: string): void => {
    setPane((prevState) => prevState !== id ? id : null);
    setCollapse(activePane === id);
  }

  const setCollapsed = (isCollapsed: boolean): void => {
    if (isCollapsed) {
      setTimeout(() => setPane(null), 380)
      setCollapse(true);
    } else {
      setCollapse(true);
    }
  }

  const providerValue = {
    activePane,
    collapsed,
    setActivePane,
    setCollapsed
  };

  return (
    <SidebarContext.Provider value={providerValue}>
      {props.children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;

