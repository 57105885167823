import { FunctionComponent, useContext, useEffect } from "react";

//OpenLayers
import Zoom from "ol/control/Zoom";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { registerOlControl } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IZoomControl } from "@/@types/components/Map/Controls/OpenLayers";

const ZoomControl: FunctionComponent<IZoomControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    duration: undefined,
    className: undefined,
    zoomInLabel: undefined,
    zoomOutLabel: undefined,
    zoomInTipLabel: undefined,
    zoomOutTipLabel: undefined,
    delta: undefined,
    target: undefined
  };

  const events = {
    change: undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => registerOlControl(context, Zoom, props, options, events), [
    props.duration,
    props.className,
    props.zoomInLabel,
    props.zoomOutLabel,
    props.zoomInTipLabel,
    props.zoomOutTipLabel,
    props.delta,
    props.target
  ]);

  return null;
};

export default ZoomControl;

