import React, { ChangeEvent, Fragment, FunctionComponent, SyntheticEvent, useContext } from "react";

//MUI
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

//OL
import OlBaseLayer from "ol/layer/Base";
import OlLayerGroup from "ol/layer/Group";

//Custom components
import LayerTreeItem from "./LayerTreeItem";
import MapContext from "@/components/Map/MapContext";
import { findLayer, flattenLayers } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { ILayerTreeProps } from "@/@types/components/Map/Controls/Custom/LayerTree";
import { isBigInt64Array } from "util/types";

const LayerTree: FunctionComponent<ILayerTreeProps> = (props) => {
  const { ready, layersCollection } = props;

  const mapContext = useContext(MapContext) as MapContextType;

  const [expanded, setExpanded] = React.useState<string[]>(["podaci"]);

  const visibility = mapContext?.getLayerVisibility();
  const hiddenLayers = visibility ? Object.keys(visibility).filter((key) => visibility[key] === false) : [];
  // console.log(hiddenLayers);

  const allLayers = flattenLayers(layersCollection.getArray()).filter((x) => !(x instanceof OlLayerGroup));
  // console.log(allLayers);

  const visibleLayers = allLayers.filter((x) => hiddenLayers.indexOf(x.get("id")) === -1);
  // console.log(visibleLayers);

  // const selected = visibleLayers.map(x => x.get("id"));
  const selected: string[] = [];

  const isVisible = (layer: OlBaseLayer) => {
    const id = layer.get("id");
    return visibility.hasOwnProperty(id) && visibility[id] === false ? false : true;
  };

  const handleToggle = (evt: SyntheticEvent, nodeIds: string[]): void => {
    setExpanded(nodeIds);
  };

  const handleSelect = (evt: ChangeEvent<{}>, nodeIds: string[] | string): void => {
    evt.preventDefault(); //prevent onNodeToggle

    if (Array.isArray(nodeIds)) {
    } else {
      const layer = flattenLayers(layersCollection.getArray(), 5).find((x) => x.get("id") === nodeIds);
      if (layer) {
        const layer_visible = isVisible(layer);

        const isGroup = layer instanceof OlLayerGroup;
        if (isGroup) {
          const childLayers = flattenLayers(layer.getLayers().getArray() as OlBaseLayer[], 5);

          const childsVisible = childLayers
            ? childLayers.filter((x: OlBaseLayer) => isVisible(x) === true && !(x instanceof OlLayerGroup))
            : [];

          const childsHidden = childLayers
            ? childLayers.filter((x: OlBaseLayer) => isVisible(x) === false && !(x instanceof OlLayerGroup))
            : [];

          const groupChecked = childsVisible.length > 0;
          const groupIndeterminate = childsVisible.length > 0 && childsHidden.length > 0;

          let newVisible: boolean;

          if (groupIndeterminate) {
            newVisible = true;
          } else {
            newVisible = !groupChecked;
          }

          if (newVisible === true) {
            childsHidden.forEach((l) => {
              const lid = l.get("id");
              // l.setVisible(true);
              mapContext.setLayerVisibility(lid, true);
            });
          } else {
            childsVisible.forEach((l) => {
              const lid = l.get("id");
              l.setVisible(false);
              mapContext.setLayerVisibility(lid, false);
            });
          }
        } else {
          mapContext.setLayerVisibility(nodeIds, !layer_visible);
        }
      }
    }
  };

  const layers = layersCollection.getArray();

  return (
    <Fragment>
      {ready && layers.length > 0 ? (
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={expanded}
          selected={selected}
          onNodeSelect={handleSelect}
          onNodeToggle={handleToggle}
          sx={{
            mb: 2
          }}
        >
          {layers.map((layer, i) => (
            <LayerTreeItem key={i} layer={layer} onNodeSelect={handleSelect} />
          ))}
        </TreeView>
      ) : null}
    </Fragment>
  );
};

export default LayerTree;
