import React, { Fragment, ReactElement } from "react";
import PropTypes from "prop-types";

//Custom Components
import { GridItem } from "@/ui/Grid";
import Typography from "@mui/material/Typography";

import formGenerator from "Lib/formGenerator";

//Types
import { FormContentProps } from "@/@types/components/formContent";
import { ControlField, FieldAny, FieldWidth, IFieldsCollection, IFieldsWControlCollection } from "@/@types/models/model";
import { GridSize } from "@mui/material";
import { FormFieldControls } from "@/@types/lib/formGenerator";

const customBottomStyle = {
  alignSelf: "flex-end"
};

const customFormHeightStyle = {
  height: "80px"
};

function FormContent(props: FormContentProps) {
  const { onFieldChange, onSubmit, columns } = props;
  const { title, mode, record, fieldNames, validation, subModels, fields } = props;

  const fg = new formGenerator();

  const handleFieldChange = (value: unknown, source: string) => {
    if (value && typeof value == "object" && value.hasOwnProperty("keyCode") && (value as { keyCode: number })["keyCode"] === 13) {
      if (onSubmit) {
        onSubmit();
      } else {
        return;
      }
    } else if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const getWidth = (f: FieldAny): GridSize => {
    if (f && f.width && f.width === "full") {
      return 12;
    } else {
      switch (columns) {
        case 1:
          return 12;
        case 2:
          return 6;
        case 3:
          return 4;
        case 4:
          return 3;
        default:
          return 6;
      }
    }
  };

  const renderFields: IFieldsWControlCollection = fieldNames ? fieldNames.map((name) => fields.find((f) => f.source === name)).filter(x => x !== undefined).map(x => x as ControlField) : [];

  let controls: FormFieldControls = {};
  switch (mode) {
    case "insert":
    case "update": {
      controls = fg.generateFormControls(
        renderFields,
        "form",
        "edit",
        record,
        validation,
        handleFieldChange,
        subModels
      );
      break;
    }
    case "view": {
      controls = fg.generateFormControls(renderFields, "form", "view", record, undefined, undefined, subModels);
      break;
    }
    case 'simpleView':{
      controls = fg.generateFormControls(renderFields, "form", "simpleView", record, undefined, undefined, subModels);
      break;
    }
  }

  renderFields.map((field) => {
    field.control = controls.hasOwnProperty(field.source) ? controls[field.source] as ReactElement : <Fragment>-</Fragment>;
  });

  return (
    <Fragment>
      {title ? (
        <GridItem xs={12}>
          <Typography variant="h6" component="h2" paragraph={true}>
            {title}
          </Typography>
        </GridItem>
      ) : null}
      {renderFields.map((f, i) => (
        <GridItem
          xs={12}
          sm={getWidth(f)}
          key={"grid-" + i}
          //style={f.bottom ? customBottomStyle : f.originalHeight ? customFormHeightStyle : null}
        >
          {f.control}
        </GridItem>
      ))}
    </Fragment>
  );
}

export default FormContent;
