import * as React from "react";
import { FunctionComponent, SyntheticEvent, useContext, useEffect, useRef } from "react";

//OpenLayers
import OlOverlay from "ol/Overlay";

//Material-UI Core Components
import IconButton from '@mui/material/IconButton';

//Custom components
import MapContext from "@/components/Map/MapContext";
import { getDefinedOptions, getEvents } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IPopupOverlay } from "@/@types/components/Map/Overlays";

const PopupOverlay: FunctionComponent<IPopupOverlay> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const overlayDiv = useRef(null);

  //@ts-ignore TODO:Variable 'overlay' implicitly has type 'any' in some locations where its type cannot be determined
  let overlay = undefined;

  const options = {
    id: undefined,
    element: undefined,
    offset: undefined,
    position: undefined,
    positioning: undefined,
    stopEvent: undefined,
    insertFirst: undefined,
    autoPan: true,
    autoPanAnimation: { duration: 100},
    autoPanMargin: undefined,
    className: undefined
  };

  const events = {
    'change': undefined,
    'change:element': undefined,
    'change:map': undefined,
    'change:offset': undefined,
    'change:position': undefined,
    'change:positioning': undefined,
    'error': undefined,
    'propertychange': undefined
  };

  useEffect(() => {

    let allOptions = Object.assign(options, props);
    //@ts-ignore TODO: Property 'position' does not exist on type 'never'
    allOptions.position = undefined; //we need to set it after adding to map, otherwise autoPan won't work
    let definedOptions = getDefinedOptions(allOptions);

    //definedOptions.element = ReactDOM.findDOMNode(this).querySelector('div');
    //@ts-ignore TODO: Property 'element' does not exist on type 'object'
    definedOptions.element = overlayDiv.current;

    // console.log('options.element', options.element);
    overlay = new OlOverlay(definedOptions);

    if (context.map) {
      const mapOverlay = context.map.getOverlayById(props.id);
      if (mapOverlay) {
        context.map.removeOverlay(mapOverlay);
      }
      context.map.addOverlay(overlay);
      if (props.position) {
        //@ts-ignore TODO: Type '"top"' is not assignable to type 'number[] | undefined'
        overlay.setPosition(props.position);
      }

    } else {
      context.initOptions.overlays.push(overlay);
    }

    let olEvents = getEvents(events, props);
    for(let eventName in olEvents) {
      //@ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
      overlay.on(eventName, olEvents[eventName]);
    }

    // console.log('popup overlay: ', context.map, props.position);
  }, [options, events])

  const handleClose = function(evt: SyntheticEvent) {
    //@ts-ignore TODO: overlay implicitly has an any type
    overlay.setPosition(undefined);
    if (props.onClose) {
      props.onClose();
    }
  };




  return(
    <div id={"overlay-" + props.id} ref={overlayDiv} className="ol-react-popup" style={{display: "block"}}>
      <IconButton
        id="popup-closer"
        className="ol-react-popup-closer"
        aria-label="close"
        size="small"
        color="primary"
        onClick={handleClose}
        style={{position: "absolute", top: "2px", right: "8px"}}
      />
      <div id="react-popup-content">
        {props.children}
      </div>
    </div>
  );
};

//@ts-ignore TODO: isTouchDevice does not exist on PopupOverlay
PopupOverlay.isTouchDevice = function() {
  try {
    document.createEvent("TouchEvent");
    return true;
  } catch(e) {
    return false;
  }
}

export default PopupOverlay;

